(function dashboardModuleClosure() {
  'use strict';

  /**
   * The `dashboard` module.
   *
   * @ngdoc overview
   * @name dashboard
   * @module
   */
  angular
    .module('dashboard', ['ionic', 'angular-cache', 'lbServices'])
    // 'isteven-multi-select', 'ui.bootstrap',

    .run(runModule);

  runModule.$inject = ['CacheFactory', 'btRequestService'];

  /**
   *
   */
  function runModule() {
    console.log('Running dashboard module at', new Date());
  }
})();
