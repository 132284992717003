/**
 * Created by Eyal on 7/29/2016.
 */

'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btChartSummary
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btChartSummary', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('dashboard/directives/common/bt-chart-summary.html'),
        scope: {
          data: '=',
          meta: '=',
        },
        controller: [
          '$scope',
          function ($scope) {
            if ($scope.meta && $scope.meta.atr_deviation && $scope.meta.atr_deviation.first_5) {
              $scope.d1 = $scope.meta.atr_deviation.first_5.toFixed(2);
              $scope.d2 = $scope.meta.atr_deviation.second_10.toFixed(2);
              $scope.d3 = $scope.meta.atr_deviation.third_15.toFixed(2);
              $scope.d4 = $scope.meta.atr_deviation.forth_30.toFixed(2);
              $scope.d5 = $scope.meta.atr_deviation.fifth_30.toFixed(2);
            }
          },
        ],
      };
    },
  ]);
