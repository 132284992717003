/**
 * Created by Motti before 6/26/2016.
 * Modified by Sergei Panpurin, David *, Mikhail Kharitonovich
 */

(function btBackTesterCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btBackTesterCtrl', btBackTesterCtrl);

  btBackTesterCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$q',
    '$ionicLoading',
    'btInsightsListConstant',
    'btDateService',
    'btSharedData',
    'btEventService',
    '$analytics',
    'btShareScopeService',
    'btSubscriptionService',
    'btBackTesterService',
    '$ionicSlideBoxDelegate',
    'btDeviceService',
    '$timeout',
    'btRestrictionService',
    'btSettingsService',
  ];

  /**
   * This is back testing controller.
   * There are form data used to form and realForm data used to getMore request.
   *
   * @ngdoc controller
   * @name BackTesterFormController
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IQService} $q - promise interface
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ecapp.IInsightsListConstant} btInsightsListConstant
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.IEventService} btEventService
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ISubscriptionService} btSubscriptionService
   * @param {ecapp.IBackTesterService} btBackTesterService
   * @param {ionic.ISlideBoxDelegateService} $ionicSlideBoxDelegate - ionic slide box delegate service
   * @param {ecapp.IDeviceService} btDeviceService
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btBackTesterCtrl(
    $scope,
    $rootScope,
    $q,
    $ionicLoading,
    btInsightsListConstant,
    btDateService,
    btSharedData,
    btEventService,
    $analytics,
    btShareScopeService,
    btSubscriptionService,
    btBackTesterService,
    $ionicSlideBoxDelegate,
    btDeviceService,
    $timeout,
    btRestrictionService,
    btSettingsService
  ) {
    console.log('Running btBackTesterCtrl');

    // +++ Initialization
    var initialDeferred = $q.defer();
    var gInitialPromise = initialDeferred.promise;

    $scope.showBanner = false;

    $scope.subStatus = btSubscriptionService.status;
    $scope.filterCollapsed = false;
    $scope.summaryCollapsed = false;
    $scope.hasWidget = 1;

    // show help information
    $scope.hasHelp = true;

    // labels for controls
    $scope.labelRowDisplay = 'Release Magnitude';

    $scope.backObject = {};

    $scope.backObject = btBackTesterService.newBackObject();

    $scope.resetGraphs = btBackTesterService.resetGraphs.bind(null, $scope.backObject);
    $scope.getMore = btBackTesterService.getMore.bind(null, $scope.backObject);
    $scope.submitForm = btBackTesterService.submitForm.bind(null, $scope.backObject);
    $scope.saveJSON = btBackTesterService.saveJSON.bind(null, $scope.backObject);

    $scope.showHelp = showHelp;

    $scope.collapseSummary = collapseSummary;
    $scope.expandSummary = expandSummary;

    // remove onShareRowInfo callback on controller destroy
    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    $scope.$on('refresh:slider', refreshSlider);

    // set listener for collapsing of graphs
    $scope.$on('requestCollapseGraphs', onRequestCollapseGraphs);

    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    // set listener for sharing row information
    btSharedData.addCallback('rowInfo', onShareRowInfo);
    btSharedData.addCallback('moments', onShareMoments);

    activate();

    // +++ Function section

    /**
     * Activate controller
     */
    function activate() {
      $scope.isBetterTrader = btSettingsService.domain === 'bettertrader';
      $scope.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';
      $scope.isOptimus = btSettingsService.domain === 'optimusfutures';
      $scope.showBanner = !btRestrictionService.hasFeature('backtesting') && $scope.isBetterTrader;
      $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');

      btSubscriptionService.updateSubscription().then(function () {
        console.log('btSubscriptionService.status', btSubscriptionService.status);
      });

      btEventService
        .init()
        .then(btShareScopeService.wait)
        .then(function () {
          $scope.backObject.dataReady = true;
          $scope.backObject.eventsList = btEventService.getEventsList();
          if ($scope.backObject.isShared) {
            initialDeferred.resolve();
          } else {
            btBackTesterService.initData($scope.backObject).then(initialDeferred.resolve);
          }

          if (btDeviceService.shouldShowMobileAlert()) {
            btDeviceService.sendGetMobileAlert();
            btShareScopeService.setDeviceAlertToTrue();
            btShareScopeService.updateProfileSettingsFromFieldsList(['deviceAlert']);
          }
        });

      btBackTesterService.resetForm($scope.backObject);
      btBackTesterService.resetGraphs($scope.backObject);
    }

    /**
     * Clean up on controller destroy
     * @param {Object} event
     * @param {Object} data
     */
    function onDestroy(event, data) {
      void event;
      void data;

      btSharedData.deleteCallback('rowInfo', onShareRowInfo);
      btSharedData.deleteCallback('moments', onShareMoments);
      console.log('Controller btBackTesterCtrl was destroyed!');
    }

    /**
     *
     */
    function onViewLoaded() {
      onViewOpen();
    }

    /**
     *
     */
    function onViewEnter() {
      onViewOpen();
    }

    /**
     *
     */
    function onViewOpen() {
      $timeout(function () {
        $analytics.eventTrack('open', { category: 'dashboard', label: 'back-tester' });
      }, 1000);
    }

    /**
     * Refresh slider
     * @param {Object} event
     * @param {Object} data
     */
    function refreshSlider(event, data) {
      void event;
      void data;

      // noinspection JSUnresolvedVariable
      if ($ionicSlideBoxDelegate._instances[0]) {
        // noinspection JSUnresolvedVariable
        $ionicSlideBoxDelegate._instances[0].update();
      }
    }

    /**
     * Collapse all charts
     * @param {Object} event
     * @param {Object} data
     */
    function onRequestCollapseGraphs(event, data) {
      void event;
      console.log('btBackTesterCtrl: Send collapse graphs!');
      $scope.$broadcast('collapseGraphs', data);
    }

    /**
     * Show help
     * @param {string} screen
     * @return {any}
     */
    function showHelp(screen) {
      if (!screen) return null;
      else if (screen === 'backtester' && btRestrictionService.hasFeature('backtesting'))
        $scope.hasHelp = !$scope.hasHelp;
      else {
        if (screen === 'backtester') {
          btRestrictionService.showUpgradePopup('backtester');
        }
      }
    }

    /**
     * Handle on successful subscription upgrade
     */
    function onSubscriptionUpgradeSuccess() {
      $scope.showBanner = !btRestrictionService.hasFeature('backtesting') && $scope.isBetterTrader;
      $scope.hasPersonalization = btRestrictionService.hasFeature('personalization');
    }

    /**
     * Collapse summary
     */
    function collapseSummary() {
      $scope.summaryCollapsed = true;
    }

    /**
     * Expand summary
     */
    function expandSummary() {
      $scope.summaryCollapsed = false;
    }

    /**
     * Run backtesting on event sharing
     * @param {btRawRelease} row
     * @param {Object} opts
     */
    function onShareRowInfo(row, opts) {
      if ($scope.$tabSelected) {
        btBackTesterService.onShareRowInfo(gInitialPromise, $scope.backObject, row, opts);
      }
    }

    /**
     * Run backtesting on moment sharing
     * @param {Number[]} moments
     * @param {Object} opts
     */
    function onShareMoments(moments, opts) {
      if ($scope.$tabSelected) {
        btBackTesterService.onShareMoments(gInitialPromise, $scope.backObject, moments, opts);
      }
    }
  }
})();
