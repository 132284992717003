/**
 * Created by Sergey Panpurin on 10/18/2016.
 */
(function btOpportunityInfoClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc directive
     * @name btOpportunityInfo
     * @memberOf dashboard
     * @description
     *  This directive show additional information about opportunity
     */
    .directive('btOpportunityInfoA', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            opportunityData: '=',
          },
          template: $templateCache.get('dashboard/directives/common/bt-opportunity-info-a.html'),
          controller: [
            '$scope',
            function ($scope) {
              void $scope;
              console.log('opportunityData', $scope.opportunityData);

              var example = {
                atr: { value: 0.7972160493827176 },
                entry: { price: 2687.63, time: 1544189460000 },
                opportunity: { value: 0 },
                ratio: { levels: [2, 3, 6], value: 1.3195920990484382 },
                reward: {
                  atr: 3.2989802476210954,
                  delta: 2.630000000000109,
                  levels: [8, 12, 16],
                  price: 2685,
                  time: 1544189640000,
                },
                risk: {
                  atr: 0.4641150918704922,
                  delta: 0.36999999999989086,
                  max: 7.7,
                  min: 2.5,
                  price: 2688,
                  time: 1544189460000,
                },
                status: 'loss',
                stop: {
                  atr: 7.700000000000265,
                  delta: 6.138563580247137,
                  price: 2693.7685635802472,
                  time: 1544190000000,
                },
                target: { atr: 7.999999999999942, delta: 6.377728395061695, price: 2681.2522716049384, time: -1 },
              };
            },
          ],
        };
      },
    ]);
})();
