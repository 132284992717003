// @ts-check
(function btBackTesterServiceClosure() {
  'use strict';

  angular.module('dashboard').factory('btBackTesterService', btBackTesterService);

  btBackTesterService.$inject = [
    'btChartService',
    'btInstrumentsService',
    'btDateService',
    '$timeout',
    'btEventService',
    'btInsightsListConstant',
    '$q',
    '$ionicLoading',
    'btRestrictionService',
    'btStrengthService',
  ];

  /**
   *
   * @param {ecapp.IChartService} btChartService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IDateService} btDateService
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.IEventService} btEventService
   * @param {ecapp.IInsightsListConstant} btInsightsListConstant
   * @param {angular.IQService} $q
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IStrengthService} btStrengthService
   * @return {ecapp.IBackTesterService}
   */
  function btBackTesterService(
    btChartService,
    btInstrumentsService,
    btDateService,
    $timeout,
    btEventService,
    btInsightsListConstant,
    $q,
    $ionicLoading,
    btRestrictionService,
    btStrengthService
  ) {
    /**
     * @typedef {Object} btBackObject
     * @property {btBacktestingForm} form - current search form
     * @property {btBacktestingForm} realForm - previous search form
     * @property {btChosenInsight[]} chosenInsights - current chosen insights
     * @property {btChosenInsight[]} realChosenInsights - previous chosen insights
     * @property {btInsightOption[]} insightOptions - insight options
     * @property {Boolean} isRealTime - is real-time backtesting
     * @property {Boolean} showCurrentRelease - show current release
     * @property {?btRawRelease|btMomentObject} currentRelease - current release
     * @property {?btBackChart} currentChart - current chart object
     * @property {?btRawEvent|btMomentObject} chartEvent - event information
     * @property {Number} total - total number of moments
     * @property {btInsight[]} insights - list of insights
     * @property {btPreparedInsight[]} fullInsights - list of prepared insights
     * @property {btStatistic} stats - trade statistics for instruments
     * @property {btBackChart[]} charts -  list of chart objects
     * @property {Boolean} hasMoreData - has more data
     * @property {String} type - type of backtesting: event or moment
     * @property {Boolean} noResults - no results
     * @property {Boolean} noResultsYet - no results yet
     * @property {Boolean} showResults - show results
     * @property {Boolean} isResultsReady - is results ready
     * @property {Boolean} dataReady - is data ready
     * @property {Boolean} notStrength - no strength
     * @property {Boolean} isShared - is shared
     * @property {Boolean} isBusy - is busy
     * @property {Boolean} hasHelp - has help
     * @property {{strengthLimit: Number}} realtime - strength limits for real-time release
     * @property {btRawEvent[]} eventsList - list of events
     * @property {btRawRelease[]} [releases] - list of releases
     */

    /**
     * @typedef {Record<string, any>} btChosenInsight
     */

    /**
     * @typedef {Record<string, any>} btInsightOption
     */

    /**
     * @typedef {Record<string, any>} btStatistic
     */

    /**
     * @typedef {Object} btBackTestingParameters
     * @property {btInstrument} [instrument] - trade idea instrument
     * @property {btMomentObject} [moment] - moment object
     * @property {String} [timeRange] - real-time
     * @property {Number[]} [strengthRange] - range of strength for backtesting
     * @property {String} [releaseCase] - identifier of expected release case
     */

    /**
     * @typedef {Object} btBacktestingForm
     * @property {Number} eventId - undefined,
     * @property {Date} from - btDateService.nYearsAgo(btDateService.getNowDate(), 3), //3 years back
     * @property {Date} till - btDateService.getNowDate(),
     * @property {Number} before - gDefaultBefore,
     * @property {Number} after - gDefaultAfter,
     * @property {Number} minRowStrength - gDefaultStrength.min,
     * @property {Number} maxRowStrength - gDefaultStrength.max,
     * @property {String} [rowStrengthChoose] - 'All',
     * @property {Number} minRevStrength - gDefaultStrength.min,
     * @property {Number} maxRevStrength - gDefaultStrength.max,
     * @property {String} [revStrengthChoose] - 'All',
     * @property {Number} minInsTotalStrength - gDefaultStrength.min,
     * @property {Number} maxInsTotalStrength - gDefaultStrength.max,
     * @property {Number} limit - gDefaultResultLimit,
     * @property {Number} skip - 0,
     * @property {Boolean} insights - true
     */

    // +++ Initialization

    // default values
    var gDefaultStrength = { min: -1, max: 1 };
    var gDefaultBefore = 15;
    var gDefaultAfter = 90;
    var gDefaultMomentAfter = 180;
    var gDefaultResultLimit = 20;
    var gDefaultStrengthLimit = 15;

    return {
      newBackObject: newBackObject,
      initData: initData,
      getMore: getMore,
      saveJSON: saveJSON,
      submitForm: submitForm,
      resetForm: resetForm,
      resetGraphs: resetGraphs,
      onShareRowInfo: onShareRowInfo,
      onShareMoments: onShareMoments,
      limitsToText: limitsToText,
      prepareInsight: prepareInsight,
      getBacktestingInterval: getBacktestingInterval,
    };

    // /**
    //  * Modify chart trade
    //  * @param {{buy: btRiskRewardMetaData, sell: btRiskRewardMetaData}} chartTrade
    //  * @param {btRawRelease} release
    //  */
    // function modifyChartTrade(chartTrade, release) {
    //   if (chartTrade && chartTrade.buy && chartTrade.sell) {
    //     chartTrade.buy.reward_min = calcMinutes(chartTrade.buy.reward_time, release.time);
    //     chartTrade.buy.risk_min = calcMinutes(chartTrade.buy.risk_time, release.time);
    //     chartTrade.sell.reward_min = calcMinutes(chartTrade.sell.reward_time, release.time);
    //     chartTrade.sell.risk_min = calcMinutes(chartTrade.sell.risk_time, release.time);
    //   }
    // }

    /**
     * Calculate minutes
     * @param {Number} time - timestamp in seconds
     * @param {Number} refTime - reference time
     * @return {String|Number}
     */
    function calcMinutes(time, refTime) {
      if (time === 0) {
        return ' - ';
      } else {
        return (time - refTime) / 60;
      }
    }

    /**
     * Prepare instrument name if name of instrument and market are different - combine them
     *
     * @param {String} marketName
     * @param {String} instrumentName
     * @return {{name: String, displayName: String}}
     */
    function prepareSymbol(marketName, instrumentName) {
      var displayName, symbol;

      if (instrumentName.split(':').pop().toLowerCase() === 'oanda') {
        var oandaSymbol = getInstrument(instrumentName);
        var instrument = btInstrumentsService.getInstrumentByField('OandaSymbol', oandaSymbol);
        var alternative = instrument.histData || instrument.OandaSymbol.replace('_', '');
        if (instrument.btName === alternative) {
          displayName = instrument.btName;
        } else {
          displayName = instrument.btName + ' (' + alternative + ')';
        }

        return {
          name: getInstrument(instrumentName),
          displayName: displayName,
        };
      } else {
        // prepare name instrument if name of instrument and market are different - combine them
        symbol = marketName.split(':').pop();
        displayName = symbol;
        if (instrumentName && displayName !== instrumentName) {
          displayName = displayName.replace('_', '');
          displayName = instrumentName + ' (' + displayName + ')';
        }
        return {
          name: symbol,
          displayName: displayName,
        };
      }
    }

    /**
     * Get related instruments
     * @param {btRawEvent|btMomentObject} event - backtesting release
     * @param {String} type - type of backtesting: moment or event
     * @return {String[]}
     */
    function getRelatedInstruments(event, type) {
      var symbols = [];
      if (type === 'moment' && event.situation && event.situation.relatedInstruments) {
        event.situation.relatedInstruments.forEach(function (symbol) {
          var oandaSymbol = getInstrument(symbol);
          var instrument = btInstrumentsService.getInstrumentByField('OandaSymbol', oandaSymbol);
          var alternative = instrument.histData || instrument.OandaSymbol.replace('_', '');
          var displayName = instrument.btName;
          if (instrument.btName !== alternative) {
            displayName += ' (' + alternative + ')';
          }
          symbols.push({ displayName: displayName, chartName: symbol, symbol: getInstrument(symbol) });
        });
      } else if (type === 'event' && event.relatedInstruments) {
        event.relatedInstruments.forEach(function (symbol) {
          var instrument = btInstrumentsService.getInstrumentBySomeSymbol(symbol);
          if (instrument && instrument.OandaSymbol) {
            var displayName = symbol + ' (' + (instrument.histData || instrument.OandaSymbol.replace('_', '')) + ')';
            symbols.push({ displayName: displayName, chartName: symbol, symbol: instrument.OandaSymbol });
          }
        });
      }

      return symbols;
    }

    /**
     * Get symbol from complex symbol
     * @param {String} symbol - complex symbol
     * @return {String}
     */
    function getInstrument(symbol) {
      return symbol.split(':')[0];
    }

    /**
     * @typedef {Object} btBackChart
     * @property {String} id - identifier
     * @property {String} time - timestamp
     * @property {String} actual - actual value
     * @property {String} forecast - forecast of actual value
     * @property {String} previous - previous value
     * @property {String} revision - revision of previous value
     * @property {String} oldReleaseStrength - old release strength object
     * @property {String} releaseStrength - release strength object
     * @property {String} revisionStrength - revision strength object
     * @property {btGraphObject[]} graphs - charts
     */

    /**
     * Process list of snapshots from LoopBack. Additionally this function create ```$scope.stats object```.
     *
     * @param {Object} rowsObj - data from database {rowId: Object, ...}
     * @param {String} prefix - chart prefix
     * @return {btBackChart[]}
     */
    function processSnapshots(rowsObj, prefix) {
      var charts = [];
      for (var rowId in rowsObj) {
        if (rowsObj.hasOwnProperty(rowId)) {
          /** @type {btGraphObject[]} */
          var chartsOfRow = [];

          /** @type {btBackTestingReleaseObject} */
          var release = rowsObj[rowId];
          console.log('btBackTesterCtrl: data element', release);

          release.graphs.forEach(processChart.bind(null, chartsOfRow, release, prefix));

          // push this row's charts to the array of all charts
          if (chartsOfRow.length) {
            charts.push({
              id: rowId,
              time: release.row.time,
              actual: release.row.actual,
              forecast: release.row.forecast,
              previous: release.row.previous,
              revision: release.row.revision,
              oldReleaseStrength: release.row.oldStrength,
              releaseStrength: release.row.releaseStrength ? release.row.releaseStrength.value : 0,
              revisionStrength: release.row.revisionStrength ? release.row.revisionStrength.value : 0,
              strength: release.row.strength ? release.row.strength : null,
              graphs: chartsOfRow,
            });
          }
        }
      }
      return charts;
    }

    /**
     * Process chart
     * @param {btGraphObject[]} chartsOfRow - list of charts connected to release
     * @param {btBackTestingReleaseObject} release - information about release
     * @param {String} prefix - chart prefix
     * @param {btServerGraphObject} chartObj
     */
    function processChart(chartsOfRow, release, prefix, chartObj) {
      if (chartObj.chartName) {
        // prepare name instrument if name of instrument and market are different - combine them
        var symbol = prepareSymbol(chartObj.chartName.split(':').pop(), chartObj.chartInstrument);

        if (chartObj.chartData && chartObj.chartData.length) {
          // prepare chart meta data: axis limit for chart and base price
          console.log('Chart bound: ', chartObj.chartInstrument, chartObj.chartName);
          var range = btChartService.getBound(chartObj.chartData, chartObj.chartMeta);
          var basePrice = chartObj.chartMeta && chartObj.chartMeta.prices ? chartObj.chartMeta.prices.after : 0;
          var relRange = btChartService.getBaseRelativeBound(range, basePrice);

          var yMarkers = btChartService.prepareYMarkets(chartObj.chartMeta);
          var xMarkers = btChartService.prepareXMarkets(chartObj.chartMeta);

          // prepare chart json data
          var chartJson = btChartService.buildStockChart(
            null,
            range,
            chartObj.chartData,
            release.row.time * 1000,
            basePrice,
            relRange,
            xMarkers,
            yMarkers
          );

          // modifyChartTrade(chartObj.chartTrade, release.row);

          // add chart object to the release list of charts
          chartsOfRow.push({
            symbol: symbol.name,
            displayName: symbol.displayName,
            chartName: prefix + ':' + chartObj.chartName,
            chartData: chartJson,
            chartMeta: chartObj.chartMeta, //,
            // chartTrade: chartObj.chartTrade
          });
        } else {
          // add empty chart object to the release list of charts
          chartsOfRow.push({
            symbol: symbol.name,
            displayName: symbol.displayName,
            chartName: null,
            chartData: null,
            chartMeta: null, //,
            // chartTrade: null
          });
        }
      }
    }

    /**
     *
     * @param {*} stats
     * @return {any}
     */
    function fixStatistics(stats) {
      for (var key in stats) {
        if (stats.hasOwnProperty(key)) {
          stats[key].name = btInstrumentsService.convertComplexName2BTName(stats[key].name);
        }
      }

      return stats;
    }

    /* -------  */

    /**
     * Get graphs and add to array. You need resetGraphs if you want to make new request.
     * This function for incrementally (get more data button).
     * ???get the graphs of each row that holds the given constrains???
     *
     * @param {btBackObject} backObject - backtesting object
     * @return {angular.IPromise<*>}
     */
    function getGraphs(backObject) {
      var data = prepareData(backObject.realForm, backObject.realChosenInsights);

      console.log('btBackTesterCtrl: sent request data', data);

      if (backObject.currentRelease && backObject.currentRelease.time) {
        data.date = backObject.currentRelease.time;
      }

      if (!backObject.isRealTime && backObject.showCurrentRelease && data.skip === 0) {
        // Process first request of past shared release
        var data_last = JSON.parse(JSON.stringify(data));
        data_last.type = 'Release@';
        data_last.insights = false;
        data_last.moments = [backObject.currentRelease.time];
        data_last.relatedInstruments = backObject.chartEvent.relatedInstruments.map(function (value) {
          var oandaSymbol = btInstrumentsService.convertBrokerName(value, 'oanda');
          if (oandaSymbol) {
            return oandaSymbol + ':OANDA';
          } else {
            return value;
          }
        });

        return $q
          .all([btEventService.getBackTesterCharts(data), btEventService.getBackTesterMoments(data_last)])
          .then(handleRealTimeCharts.bind(null, backObject), onError.bind(null, backObject));
      } else {
        // Process others requests: real-time request, form request, get more request
        return btEventService
          .getBackTesterCharts(data)
          .then(handleCharts.bind(null, backObject), onError.bind(null, backObject));
      }
    }

    /**
     * Handle realtime charts
     * @param {btBackObject} backObject - backtesting object
     * @param {Array} data - response data [past, last]
     */
    function handleRealTimeCharts(backObject, data) {
      var last = {};
      if (data[1].getGraphsOfMoments !== undefined) {
        last = data[1].getGraphsOfMoments;
      }

      var rowsObj = last.charts;

      var charts = processSnapshots(rowsObj, 'bt');

      charts.sort(function (a, b) {
        return b.time - a.time;
      });

      backObject.currentChart = charts[0];

      handleCharts(backObject, data[0]);
    }

    /**
     * Handle charts
     * @param {btBackObject} backObject - backtesting object
     * @param {{getGraphsOfRows, getGraphsOfMoments, releases}} res - response
     */
    function handleCharts(backObject, res) {
      var kind;
      var data;

      if (res.getGraphsOfRows) {
        kind = 'release';
        data = res.getGraphsOfRows;
      }

      if (res.getGraphsOfMoments) {
        kind = 'moment';
        data = res.getGraphsOfMoments;
      }

      if (res.releases) {
        res.releases.forEach(function (release) {
          if (release.releaseStrength) {
            release.strength = btStrengthService.prepareStrength(release.releaseStrength.value, release.time);
          }
        });
      }

      console.log('btBackTesterCtrl: Got ' + Object.keys(data.charts).length + ' charts');

      // list of charts and total number of releases
      var rowsObj = data.charts;
      backObject.total = data.total;

      console.log('btBackTesterCtrl: BackTester data from server', rowsObj);

      // var charts = processSnapshots(rowsObj);
      var charts = processSnapshots(rowsObj, 'bt');

      backObject.insights = data.insights2;
      backObject.fullInsights = backObject.insights.map(function (t) {
        return prepareInsight(backObject, t, kind, backObject.currentRelease);
      });
      backObject.stats = fixStatistics(data.stats2);

      charts.sort(function (a, b) {
        return b.time - a.time;
      });

      backObject.charts = backObject.charts.concat(charts);

      console.log('btBackTesterCtrl: show BackTester statistic', backObject.stats);

      if (charts.length === 0 || backObject.charts.length === backObject.total) {
        console.log('btBackTesterCtrl: no more data');
        backObject.hasMoreData = false;
      }

      if (backObject.type === 'event') {
        backObject.chartEvent = backObject.eventsList.filter(function (event) {
          return event.id === backObject.form.eventId;
        })[0];

        backObject.chartEvent = btEventService.addCurrencyInfo(backObject.chartEvent);
      }

      if (backObject.showCurrentRelease && backObject.currentRelease.graphs === undefined) {
        backObject.currentRelease.graphs = getRelatedInstruments(backObject.chartEvent, backObject.type);
      }

      console.log('btBackTesterCtrl: number of charts', backObject.charts.length);

      if (backObject.charts.length) {
        backObject.noResults = false;
        backObject.showResults = true;
        backObject.isResultsReady = true;
      } else {
        backObject.noResults = true;
        backObject.showResults = false;
        backObject.isResultsReady = false;
      }
    }

    /**
     * Handle error
     * @param {btBackObject} backObject - backtesting object
     * @param {Error} err - error object
     */
    function onError(backObject, err) {
      console.error('btBackTesterCtrl: error in getBackTesterCharts:', err);
      $ionicLoading.hide();
      backObject.noResults = true;
    }

    /**
     * @typedef {Object} btPreparedInsight
     * @property {String} _id - insight identifier
     * @property {String} time - release time
     * @property {String} rowId - release identifier
     * @property {Number} eventId - event identifier
     * @property {Number|Null} releaseStrength - release strength
     * @property {Object} insight - insight data
     * @property {String} market - market name
     * @property {String} kind - kind of insight: 'release' or 'moment'
     */

    /**
     *
     * @param {*} backObject
     * @param {*} data
     * @param {*} kind
     * @param {*} release
     * @return {btPreparedInsight}
     */
    function prepareInsight(backObject, data, kind, release) {
      return {
        _id: 'NA',
        time: release ? release.time : 'NA',
        rowId: release ? release.rowId : 'NA',
        eventId: backObject.form.eventId,
        isTimeFaked: !release,
        releaseStrength: 0,
        insight: {
          type: 'back-test',
          templateVars: {
            rate: data.win + ' out of ' + data.total + ' times',
            market: data.market,
          },
          totalSurpriseStrength: 1,
          totalStrength: 1,
          strengths: {},
          template:
            data.win +
            ' out of ' +
            data.total +
            ' times ' +
            data.market +
            ' was on the ' +
            data.action +
            ' in similar situations',
          data: data,
          id: data.action === 'uptrend' ? 300 : 301,
        },
        market: data.market,
        kind: kind,
      };
    }

    /**
     * Prepare data
     * @param {Object} realForm
     * @param {*} realChosenInsights
     * @return {Object}
     */
    function prepareData(realForm, realChosenInsights) {
      // will sent to server
      var data = {};

      // collect data from the form and change the time format for to seconds.
      for (var key in realForm) {
        if (realForm.hasOwnProperty(key)) {
          if (key === 'from' || key === 'till') data[key] = Math.round(realForm[key].getTime() / 1000.0);
          else data[key] = realForm[key];
        }
      }

      // collect insights constrains from the chosen insights
      data.insightsList = {};
      realChosenInsights.forEach(function (ins) {
        data.insightsList[ins.id] = {
          minInsightStrength: ins.minInsightStrength,
          maxInsightStrength: ins.maxInsightStrength,
        };
      });

      return data;
    }

    /* ------ */

    /**
     * Create new backtesting object
     * @return {btBackObject}
     */
    function newBackObject() {
      /** @type {btInsightOption[]} */
      var insightOptions = btInsightsListConstant.map(function (insight) {
        insight.minInsightStrength = gDefaultStrength.min;
        insight.maxInsightStrength = gDefaultStrength.max;
        return insight;
      });

      return {
        type: 'event',
        form: newFormObject(),
        realForm: newFormObject(),
        chosenInsights: [],
        realChosenInsights: [],
        insightOptions: insightOptions,

        isRealTime: false,
        showCurrentRelease: false,
        currentRelease: null,
        currentChart: null,
        chartEvent: null,
        total: 0,
        insights: [],
        fullInsights: [],
        stats: null,
        charts: [],

        hasMoreData: false,
        noResults: false,
        noResultsYet: false,
        showResults: false,
        isResultsReady: false,
        dataReady: false,
        notStrength: false,
        isShared: false,
        isBusy: false,
        hasHelp: false,

        realtime: { strengthLimit: gDefaultStrengthLimit },
        eventsList: [],
      };
    }

    /**
     * Create new backtesting form
     * @return {btBacktestingForm}
     */
    function newFormObject() {
      return {
        eventId: null,
        from: btDateService.nYearsAgo(btDateService.getNowDate(), 3), //3 years back
        till: btDateService.getNowDate(),
        before: gDefaultBefore,
        after: gDefaultAfter,
        minRowStrength: gDefaultStrength.min,
        maxRowStrength: gDefaultStrength.max,
        rowStrengthChoose: 'All',
        minRevStrength: gDefaultStrength.min,
        maxRevStrength: gDefaultStrength.max,
        revStrengthChoose: 'All',
        minInsTotalStrength: gDefaultStrength.min,
        maxInsTotalStrength: gDefaultStrength.max,
        limit: gDefaultResultLimit,
        skip: 0,
        insights: true,
      };
    }

    /**
     * Method to reset the form
     *
     * @ngdoc method
     * @name resetForm
     * @memberOf dashboard.BackTesterFormController
     * @param {btBackObject} backObject - backtesting object
     */
    function resetForm(backObject) {
      backObject.form = {
        eventId: undefined,
        from: btDateService.nYearsAgo(btDateService.getNowDate(), 3), //3 years back
        till: btDateService.getNowDate(),
        before: gDefaultBefore,
        after: gDefaultAfter,
        minRowStrength: gDefaultStrength.min,
        maxRowStrength: gDefaultStrength.max,
        rowStrengthChoose: 'All',
        minRevStrength: gDefaultStrength.min,
        maxRevStrength: gDefaultStrength.max,
        revStrengthChoose: 'All',
        minInsTotalStrength: gDefaultStrength.min,
        maxInsTotalStrength: gDefaultStrength.max,
        limit: gDefaultResultLimit,
        skip: 0,
        insights: true,
      };

      backObject.insightOptions.forEach(function (ins) {
        // noinspection JSUndefinedPropertyAssignment
        ins.selected = false;
      });
    }

    /**
     * Reset statistic
     *
     * @param {btBackObject} backObject - backtesting object
     */
    function resetStats(backObject) {
      backObject.stats = {};
    }

    /**
     * Reset charts data, prepare to load new data
     *
     * @ngdoc method
     * @name resetGraphs
     * @memberOf dashboard.BackTesterFormController
     * @param {btBackObject} backObject - backtesting object
     */
    function resetGraphs(backObject) {
      backObject.showResults = false;
      backObject.isResultsReady = false;
      backObject.noResults = false;
      backObject.noResultsYet = false;
      backObject.hasMoreData = true;
      backObject.form.skip = 0;
      backObject.total = 0;
      backObject.charts = [];
      backObject.currentChart = null;
    }

    //noinspection JSUnusedGlobalSymbols
    /**
     * Get more data using realForm data
     * @param {btBackObject} backObject - backtesting object
     */
    function getMore(backObject) {
      backObject.realForm.skip += gDefaultResultLimit;
      getGraphs(backObject).finally();
    }

    /**
     * Init first view data
     *
     * @ngdoc method
     * @name initData
     * @memberOf dashboard.BackTesterFormController
     * @param {btBackObject} backObject - backtesting object
     * @return {angular.IPromise<*>}
     */
    function initData(backObject) {
      var deferred = $q.defer();

      /**
       *
       * @param {*} json
       */
      function commonPart(json) {
        backObject.charts = json.charts;
        backObject.total = json.total;
        backObject.stats = json.stats;
        backObject.realForm = json.realForm;
        // noinspection JSCheckFunctionSignatures
        backObject.realForm.from = new Date(backObject.realForm.from);
        // noinspection JSCheckFunctionSignatures
        backObject.realForm.till = new Date(backObject.realForm.till);
        backObject.form = backObject.realForm;
        backObject.showResults = true;
        $timeout(
          function () {
            backObject.isResultsReady = true;
          },
          0,
          false
        );
        backObject.insights = json.insights;
        backObject.fullInsights = backObject.insights.map(function (t) {
          return prepareInsight(backObject, t, 'release', backObject.currentRelease);
        });
      }

      // noinspection JSUnresolvedFunction
      $.getJSON('research_init.json', function (json) {
        backObject.chartEvent = json.chartEvent;
        backObject.currentRelease = null;
        backObject.isRealTime = false;
        backObject.showCurrentRelease = false;
        commonPart(json);
        deferred.resolve();
      });

      return deferred.promise;
    }

    /**
     * Process real-time queries
     *
     * @ngdoc method
     * @name realTimeRequest
     * @memberOf dashboard.BackTesterFormController
     * @param {btBackObject} backObject - backtesting object
     * @param {btRawRelease} release - the constrains of the query
     * @param {btBackTestingParameters} opts - timeRange and strengthRange
     * @return {*}
     */
    function realTimeRequest(backObject, release, opts) {
      // TODO
      // if (release === undefined) {
      //   backObject.noResultsYet = true;
      //   console.log('btBackTesterCtrl: bad release!');
      //   return;
      // }

      var minRowStrength = null;
      var maxRowStrength = null;

      if (release.releaseStrength) {
        backObject.notStrength = false;

        if (window.btSettings.BT_BACKTESTING_VERSION === '1') {
          var magnitude = btStrengthService.getReleaseMagnitudeId(release);
          var option = btStrengthService.getMagnitudeOptionById(magnitude);
          minRowStrength = option.min;
          maxRowStrength = option.max;
        } else {
          var limits = btStrengthService.limits(release.releaseStrength.value, backObject.realtime.strengthLimit);
          minRowStrength = limits[0];
          maxRowStrength = limits[1];
        }

        backObject.form.minRowStrength = minRowStrength;
        backObject.form.maxRowStrength = maxRowStrength;
      } else {
        backObject.notStrength = true;
        backObject.form.minRowStrength = -1;
        backObject.form.maxRowStrength = 1;
      }

      backObject.form.from = btDateService.nYearsAgo(btDateService.getDateFromRow(release), 3);
      backObject.form.till = btDateService.getDateFromRowMinusDay(release);

      backObject.realForm = {
        eventId: release.eventId,
        from: btDateService.nYearsAgo(btDateService.getDateFromRow(release), 3),
        till: btDateService.getDateFromRowMinusDay(release),
        before: gDefaultBefore,
        after: gDefaultAfter,
        minRowStrength: minRowStrength,
        maxRowStrength: maxRowStrength,
        minRevStrength: null,
        maxRevStrength: null,
        minInsTotalStrength: null,
        maxInsTotalStrength: null,
        limit: gDefaultResultLimit,
        skip: 0,
        insights: true,
      };

      // noinspection EqualityComparisonWithCoercionJS
      if (opts != null) {
        console.log(opts);
        if (opts.timeRange && opts.timeRange === 'real-time') {
          backObject.form.from = btDateService.nYearsAgo(btDateService.getDateFromRow(release), 3);
          backObject.form.till = btDateService.getDateFromRowMinusDay(release);
          backObject.realForm.from = btDateService.nYearsAgo(btDateService.getDateFromRow(release), 3);
          backObject.realForm.till = btDateService.getDateFromRowMinusDay(release);
        }
        if (opts.releaseCase) {
          var magnitudeCase = btStrengthService.getMagnitudeOptionByCase(opts.releaseCase);
          if (magnitudeCase) {
            backObject.form.minRowStrength = magnitudeCase.min;
            backObject.form.maxRowStrength = magnitudeCase.max;
            backObject.realForm.minRowStrength = magnitudeCase.min;
            backObject.realForm.maxRowStrength = magnitudeCase.max;
          }
        } else if (opts.strengthRange) {
          backObject.form.minRowStrength = opts.strengthRange[0];
          backObject.form.maxRowStrength = opts.strengthRange[1];
          backObject.realForm.minRowStrength = opts.strengthRange[0];
          backObject.realForm.maxRowStrength = opts.strengthRange[1];
        }
      }

      resetStats(backObject);
      return getGraphs(backObject).finally(function () {
        backObject.isBusy = false;
      });
    }

    /**
     * Check form, save form and get graphs
     *
     * @ngdoc method
     * @name submitForm
     * @methodOf dashboard.BackTesterFormController
     * @param {btBackObject} backObject - backtesting object
     * @param {*} isValidForm {Boolean} is form valid
     * @return {angular.IPromise<*>}
     */
    function submitForm(backObject, isValidForm) {
      if (!isValidForm) {
        alert('Please fill in all the required fields correctly.');
      } else {
        resetGraphs(backObject);

        if (!btRestrictionService.hasFeature('backtesting')) {
          return btRestrictionService.showUpgradePopup('backtester').finally(function () {
            return $q.reject(new Error('Update subscription!'));
          });
        }

        backObject.type = 'event';
        backObject.realForm = angular.extend({}, backObject.form);
        backObject.currentRelease = null;
        backObject.isRealTime = false;
        backObject.showCurrentRelease = false;
        backObject.realChosenInsights = backObject.chosenInsights;
        resetStats(backObject);
        return getGraphs(backObject);
      }
    }

    /**
     * Save JSON for initial data
     *
     * @param {btBackObject} backObject - backtesting object
     */
    function saveJSON(backObject) {
      var data = JSON.stringify({
        situation: backObject.situation,
        realtime: backObject.realtime,
        currentRelease: backObject.currentRelease,
        chartEvent: backObject.chartEvent,
        currentChart: backObject.currentChart,
        charts: backObject.charts,
        realForm: backObject.realForm,
        total: backObject.total,
        stats: backObject.stats,
        insights: backObject.insights,
      });

      /**
       *
       * @param {*} content
       * @param {*} fileName
       * @param {*} contentType
       */
      function download(content, fileName, contentType) {
        var a = document.createElement('a');
        var file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      }

      download(data, 'research_init.json', 'text/plain');

      console.log(data);
    }

    /**
     * Get moment graphs
     * @param {btBackObject} backObject - backtesting object
     * @param {btMomentObject} moment
     * @param {Number[]} history
     * @return {angular.IPromise<*>}
     */
    function getMomentGraphs(backObject, moment, history) {
      var instruments = moment.situation.relatedInstruments;

      var data = {
        type: 'Market@',
        date: parseInt(new Date(moment.time) / 1000),
        moments: history,
        relatedInstruments: instruments,
        insights: true,
        before: 60,
        after: gDefaultMomentAfter,
        skip_after: 0,
        interval: 5,
        skip: 0,
        limit: 20,
      };

      console.log('btBackTesterCtrl: sent request data', data);

      if (!backObject.isRealTime && backObject.showCurrentRelease) {
        // non real-time
        var data_last = JSON.parse(JSON.stringify(data));
        data_last.insights = false;
        data_last.moments = [new Date(moment.time).getTime() / 1000];

        return $q
          .all([btEventService.getBackTesterMoments(data), btEventService.getBackTesterMoments(data_last)])
          .then(handleRealTimeCharts.bind(null, backObject), onError.bind(null, backObject));
      } else {
        // real-time
        backObject.currentRelease = prepareMomentRelease(moment);
        return btEventService
          .getBackTesterMoments(data)
          .then(handleCharts.bind(null, backObject), onError.bind(null, backObject));
      }
    }

    /**
     * Run backtesting on event sharing
     * @param {*} initialPromise
     * @param {btBackObject} backObject - backtesting object
     * @param {btRawRelease} row
     * @param {btBackTestingParameters} opts
     * @return {angular.IPromise<*>}
     */
    function onShareRowInfo(initialPromise, backObject, row, opts) {
      if (backObject.isBusy) {
        return $q.reject(new Error('Busy'));
      }

      backObject.isBusy = true;

      if (!btRestrictionService.hasFeature('backtesting')) {
        btRestrictionService.showUpgradePopup('backtester').finally(function () {
          backObject.isBusy = false;
        });
        // preparePlans(true);
        // backObject.showSubscriptionPopup = true;

        $ionicLoading.hide();
        return $q.reject(new Error('Bad Subscription'));
      }

      return initialPromise
        .then(function () {
          console.log('btBackTesterCtrl: get shared row information');
          console.log('btBackTesterCtrl: check data', row);

          //noinspection JSUnresolvedVariable
          if (backObject.$tabSelected || true) {
            backObject.type = 'event';

            backObject.form.eventId = row.eventsInfo.id;

            backObject.chartEvent = row.eventsInfo;
            var delta = checkRealTime(btDateService.getDateFromRow(row), gDefaultAfter);
            backObject.showCurrentRelease = true;
            backObject.isRealTime = delta >= 0;
            backObject.currentRelease = row;

            resetGraphs(backObject);
            backObject.hasHelp = false;
            return realTimeRequest(backObject, row, opts);
          } else {
            return $q.resolve();
          }
        })
        .finally(function () {
          backObject.isBusy = false;
        });
    }

    /**
     * Run backtesting on moment sharing
     * @param {*} initialPromise
     * @param {btBackObject} backObject - backtesting object
     * @param {Number[]} moments
     * @param {btBackTestingParameters} opts
     * @return {angular.IPromise<*>}
     */
    function onShareMoments(initialPromise, backObject, moments, opts) {
      if (backObject.isBusy) {
        return $q.reject(new Error('Busy'));
      }

      backObject.isBusy = true;

      if (!btRestrictionService.hasFeature('backtesting')) {
        btRestrictionService.showUpgradePopup('backtester').finally(function () {
          backObject.isBusy = false;
        });
        // preparePlans(true);
        // backObject.showSubscriptionPopup = true;

        $ionicLoading.hide();
        backObject.isBusy = false;
        return $q.reject(new Error('Bad Subscription'));
      }

      return initialPromise
        .then(function () {
          console.log('btBackTesterCtrl: get shared moments');
          console.log('btBackTesterCtrl: check data', moments);

          //noinspection JSUnresolvedVariable
          if (backObject.$tabSelected || true) {
            backObject.type = 'moment';

            backObject.form.eventId = undefined;

            backObject.currentRelease = opts.moment;
            backObject.chartEvent = prepareMomentEvent(opts.moment);
            var delta = checkRealTime(opts.moment.time, gDefaultMomentAfter);
            backObject.showCurrentRelease = delta !== 1;
            backObject.isRealTime = delta === 0;

            resetGraphs(backObject);
            backObject.hasHelp = false;
            return getMomentGraphs(backObject, opts.moment, moments);
          } else {
            return $q.resolve();
          }
        })
        .finally(function () {
          backObject.isBusy = false;
        });
    }

    /**
     * Check release date and return: 1 - for future release, 0 - for new release, -1 for old release
     * @param {Date|Number} date - date object
     * @param {Number} delay - number of minutes after release
     * @return {Number}
     */
    function checkRealTime(date, delay) {
      var now = btDateService.getNowDate();

      if (date instanceof Number) {
        date = new Date(date);
      }

      var delta = btDateService.getDifferenceInMinutes(now, date);

      // future
      if (date > now) return 1;

      // released but still under delay
      if (delta < delay) return 0;

      // released more than delay
      return -1;
    }

    /**
     * Change time from date to timestamp in seconds
     * @param {btMomentObject} moment - some object
     * @return {btMomentObject}
     */
    function prepareMomentRelease(moment) {
      var release = JSON.parse(JSON.stringify(moment));
      release.time = new Date(release.time).getTime() / 1000;
      return release;
    }

    /**
     * Change time from date to timestamp in seconds
     * @param {btMomentObject} moment - some object
     * @return {btMomentObject}
     */
    function prepareMomentEvent(moment) {
      var event = JSON.parse(JSON.stringify(moment));
      event.time = new Date(event.time).getTime() / 1000;
      return event;
    }

    /**
     *
     * @param {Number} minValue
     * @param {Number} maxValue
     * @return {string}
     */
    function limitsToText(minValue, maxValue) {
      return btStrengthService.limitsToText(minValue, maxValue);
    }

    /**
     * @typedef {Object} btMomentLike
     * @property {Number} time - timestamp in seconds
     */

    /**
     * Get backtesting interval
     *
     * @param {String} type - backtesting type: events or markets
     * @param {btMomentLike[]} moments - list of moments
     * @return {*}
     */
    function getBacktestingInterval(type, moments) {
      var days = 0;
      var difference = 'history';

      if (moments.length > 0) {
        var min = moments[0].time;
        var max = moments[0].time;

        moments.forEach(function (moment) {
          if (moment.time < min) min = moment.time;
          if (moment.time > max) max = moment.time;
        });

        days = btDateService.getDifferenceInDays(max * 1000, min * 1000);
        difference = btDateService.getHumanizedDifference(max * 1000, min * 1000);
      }

      return {
        times: moments.length,
        days: days,
        interval: normalizeBacktestingInterval(type, moments.length, 20, difference),
      };
    }

    /**
     * Normalize backtesting interval
     *
     * @param {String} type - backtesting type: events or markets
     * @param {Number} count - number of moments
     * @param {Number} limit - max number of moments
     * @param {String} rangeText - calculated range text
     * @return {String}
     */
    function normalizeBacktestingInterval(type, count, limit, rangeText) {
      if (count < limit) {
        if (type === 'events') return '3 years';
        if (type === 'markets') return '1 year';
        return rangeText;
      } else {
        return rangeText;
      }
    }
  }
})();
