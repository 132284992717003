/**
 * Created by Sergey Panpurin on 10/18/2016.
 */
(function btOpportunityInfoClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc directive
     * @name btOpportunityInfo
     * @memberOf dashboard
     * @description
     *  This directive show additional information about opportunity
     */
    .directive('btOpportunityInfo', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            opportunityData: '=',
          },
          template: $templateCache.get('dashboard/directives/common/bt-opportunity-info.html'),
          controller: [
            '$scope',
            function ($scope) {
              void $scope;
            },
          ],
        };
      },
    ]);
})();
