/**
 * Created by yatree on 05/08/16.
 */

(function btCorrelationCardClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc controller
     * @name btCorrelationCard
     * @memberOf dashboard
     * @description
     *  Display correlation card
     */
    .directive('btCorrelationCard', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('dashboard/directives/correlation/bt-correlation-card.html'),
          scope: {
            data: '=',
            threshold: '@',
            name1: '=',
            name2: '=',
          },
          controller: [
            '$scope',
            function ($scope) {
              // Checking if getting value is 0, 1 or -1 (neutralGet=true when getting = 0)
              var updateIconSettings = function () {
                $scope.threshold = Math.abs($scope.threshold);
                $scope.zeroCorrelation = $scope.data.correlation === 0;

                $scope.neutralGet = false;
                $scope.gettingStronger = false;
                if ($scope.data.getting === 0) {
                  $scope.neutralGet = true;
                } else if ($scope.data.getting === 1) {
                  $scope.gettingStronger = true;
                } else if ($scope.data.getting === -1) {
                  $scope.gettingStronger = false;
                }

                // Checking if correlation is positive/negative and if it passes threshold, similar to bt-correlation-meter directive
                if ($scope.data.correlation > 0) {
                  $scope.positive = true;
                  $scope.passThreshold = $scope.data.correlation > $scope.threshold;
                }
                // If correlation is negative:
                else if ($scope.data.correlation < 0) {
                  $scope.threshold = Math.abs($scope.threshold) * -1;
                  $scope.positive = false;
                  $scope.passThreshold = $scope.data.correlation < $scope.threshold;
                }
                // Set message based on threshold and positive values
                if ($scope.passThreshold && !$scope.positive && !$scope.zeroCorrelation) {
                  $scope.message = 'Strong Negative';
                } else if (!$scope.passThreshold && !$scope.positive && !$scope.zeroCorrelation) {
                  $scope.message = 'Negative Correlation';
                } else if ($scope.zeroCorrelation) {
                  $scope.message = 'No Correlation';
                } else if (!$scope.passThreshold && $scope.positive && !$scope.zeroCorrelation) {
                  $scope.message = 'Positive Correlation';
                } else if ($scope.passThreshold && $scope.positive && !$scope.zeroCorrelation) {
                  $scope.message = 'Strong Positive';
                }
              };

              updateIconSettings();
              $scope.$watch('data', function () {
                updateIconSettings();
              });
            },
          ],
        };
      },
    ]);
})();
