(function btInstrumentPickerClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc controller
     * @name btInstrumentPicker
     * @memberOf dashboard
     * @description
     *  Picker for instruments
     */
    .directive('btInstrumentPicker', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          replace: true, // Is at good idea to set replace: false
          scope: {
            btSelected: '=', // Selected instrument
            btLabel: '@', // Label of input
            btOffset: '@', // initial offset to select instruments
          },
          template: $templateCache.get('dashboard/directives/common/bt-instrument-picker.html'),

          controller: [
            '$scope',
            'btRequestService',
            function ($scope, btRequestService) {
              console.log('btSelect');

              btRequestService.getStockInstruments().then(function (data) {
                $scope.stocks = [];
                data.forEach(function (value) {
                  $scope.stocks.push({ name: value });
                });

                console.log('Receive instruments');
              });
            },
          ],
        };
      },
    ]);
})();
