// @ts-check
(function btInsightsListConstantClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc constant
     * @name btInsightsListConstant
     * @memberOf dashboard
     * @description
     *  ???
     */
    .constant(
      'btInsightsListConstant',
      /** @type {IInsightsListConstant} */ [
        { id: 9, label: 'Most Negative Surprise' },
        { id: 10, label: 'Most Positive Surprise' },
        { id: 11, label: 'First Positive Surprise' },
        { id: 12, label: 'First Negative Surprise' },
        { id: 13, label: 'First Positive Reading' },
        { id: 14, label: 'First Negative Reading' },
        { id: 15, label: 'Biggest Climb' },
        { id: 16, label: 'Biggest Drop' },
        { id: 17, label: 'Min Reading' },
        { id: 18, label: 'Max Reading' },
        { id: 19, label: 'First Negative Revision' },
        { id: 20, label: 'First Positive Revision' },
        { id: 21, label: 'First Negative Revision Surprise' },
        { id: 22, label: 'First Positive Revision Surprise' },
        { id: 23, label: 'Most Negative Revision Surprise' },
        { id: 24, label: 'Most Positive Revision Surprise' },
        { id: 25, label: 'Min Revision' },
        { id: 26, label: 'Max Revision' },
      ]
    );
})();
