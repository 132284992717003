/**
 * Created by yatree on 05/08/16.
 */

(function btCorrelationMeterClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc controller
     * @name btCorrelationMeter
     * @memberOf dashboard
     * @description
     *  ???
     */
    .directive('btCorrelationMeter', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('dashboard/directives/correlation/bt-correlation-meter.html'),
          scope: {
            data: '=',
            threshold: '=',
          },
          controller: [
            '$scope',
            function ($scope) {
              var updateViewValues = function () {
                // Set width and margin values for positive/negative bars
                $scope.width = Math.abs($scope.data.correlation) * 50;
                $scope.margin = 50 - $scope.width;
                // Set correlation value to a number between 1 and 100 (comes in as decimal)
                $scope.correlationValue = parseInt(($scope.data.correlation * 100).toFixed(0));

                // Checking if correlation is positive and greater than the threshold
                if ($scope.data.correlation > 0) {
                  $scope.positive = true;
                  $scope.passThreshold = $scope.data.correlation > $scope.threshold;
                } else if ($scope.data.correlation < 0) {
                  // If correlation is negative
                  $scope.positive = false;
                  $scope.passThreshold = $scope.data.correlation < $scope.threshold;
                }
              };
              updateViewValues();
              $scope.$watch('data', function () {
                updateViewValues();
              });
            },
          ],
          link: function (scope, el) {
            // DOM Variables of correlation meter
            var negativeContainer = el[0].querySelector('.correlation-meter-negative-container').style;
            var positiveContainer = el[0].querySelector('.correlation-meter-positive-container').style;
            var label = el[0].querySelector('.label1').style;
            var updateView = function () {
              //Setting widths, margins and colors based on correlation value
              if (scope.data.correlation < 0) {
                label.marginLeft = scope.margin - 3 + '%';
                negativeContainer.width = scope.width + '%';
                negativeContainer.marginLeft = scope.margin + '%';

                positiveContainer.backgroundColor = 'black';
                positiveContainer.width = '50%';
                positiveContainer.marginLeft = '0px';
                if (scope.passThreshold) {
                  label.color = '#A72D39';
                  negativeContainer.backgroundColor = '#A72D39';
                } else {
                  negativeContainer.backgroundColor = '#33363D';
                  label.color = '#616773';
                }
              } else if (scope.data.correlation > 0) {
                label.marginLeft = 97 - scope.margin + '%';
                positiveContainer.width = scope.width + '%';
                positiveContainer.marginLeft = 50 + '%';

                negativeContainer.margin = '0px';
                negativeContainer.width = '0px';
                negativeContainer.backgroundColor = 'black';
                if (scope.passThreshold) {
                  label.color = '#48C064';
                  positiveContainer.backgroundColor = '#48C064';
                } else {
                  positiveContainer.backgroundColor = '#33363D';
                  label.color = '#616773';
                }
              } else {
                // If correlation is zero
                label.marginLeft = '50%';
                positiveContainer.width = '50%';
                negativeContainer.width = '50%';
                positiveContainer.backgroundColor = 'black';
                negativeContainer.backgroundColor = 'black';
              }
            };
            updateView();
            scope.$watch('data', function () {
              updateView();
            });
          },
        };
      },
    ]);
})();
