'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btSentiment
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btSentiment', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          symbol: '=',
          date: '=',
          row: '=releaseData',
        },
        template: $templateCache.get('dashboard/directives/sentiment/bt-sentiment.html'),
      };
    },
  ])
  /**
   * @ngdoc filter
   * @name btSentimentLabel
   * @memberOf dashboard
   * @description
   *  ???
   */
  .filter('btSentimentLabel', function () {
    return function (input) {
      var label = 'WEAK STRENGTH OR REACTION';
      var labels = ['STRONG SELL', 'SELL', 'NEUTRAL', 'BUY', 'STRONG BUY'];

      var number = parseInt(input);

      if (number >= -2 && number <= 2) {
        label = labels[number + 2];
      }

      return label;
    };
  })
  /**
   * @ngdoc filter
   * @name btSentimentClass
   * @memberOf dashboard
   * @description
   *  ???
   */
  .filter('btSentimentClass', function () {
    return function (input) {
      var label = 'sw-none';
      var labels = ['sw-sell', 'sw-sell', 'sw-neutral', 'sw-buy', 'sw-buy'];

      var number = parseInt(input);

      if (number >= -2 && number <= 2) {
        label = labels[number + 2];
      }

      return label;
    };
  });
