'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btCorrelationSidebar
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btCorrelationSidebar', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('dashboard/directives/correlation/bt-correlation-sidebar.html'),
        scope: {
          data: '=',
        },
      };
    },
  ]);
