// @ts-check
(function btEventServiceClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * ???
     *
     * @ngdoc service
     * @name btEventService
     * @memberOf dashboard
     */
    .factory('btEventService', btEventService);

  btEventService.$inject = [
    '$q',
    'EventsInfo',
    'btCurrenciesService',
    'Rows',
    'btInstrumentsService',
    'btRequestService',
    'btSettingsService',
  ];

  /**
   *
   * @param {angular.IQService} $q
   * @param {ecapp.IGeneralLoopbackService} lbEventsInfo
   * @param {ecapp.ICurrenciesService} btCurrenciesService
   * @param {ecapp.IGeneralLoopbackService} lbRows
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.IRequestService} btRequestService
   * @param {ecapp.ISettingsService} btSettingsService
   * @return {ecapp.IEventService}
   */
  function btEventService(
    $q,
    lbEventsInfo,
    btCurrenciesService,
    lbRows,
    btInstrumentsService,
    btRequestService,
    btSettingsService
  ) {
    var gEventsList = null;
    btCurrenciesService.init();

    return {
      /*
       return is this structure:
       {flag_name: base64flag, ...}
       */
      init: init,

      getEventsList: getEventsList,
      getEventInfo: getEventInfo,
      getEventById: getEventById,
      addCurrencyInfo: addCurrencyInfo,
      getBackTesterCharts: getBackTesterCharts,
      getBackTesterMoments: getBackTesterMoments,
      // runBacktestingV1: runBacktestingV1,
      // runBacktestingV1Wrapper: runBacktestingV1WrapperMoments,
      loadReleases: loadReleases,
    };

    /**
     *
     * @return {angular.IPromise<any>}
     */
    function init() {
      return cacheEventsList().then(function () {
        console.log('btEventService: success');
      });
    }

    /**
     *
     * @return {*}
     */
    function cacheEventsList() {
      if (!gEventsList) {
        var query = {
          filter: {
            fields: [
              'name',
              'eventType',
              'currency',
              'country',
              'id',
              'importance',
              'direction',
              'relatedInstruments',
              'pronunciation',
              'eventsDescription',
            ],
            include: 'eventsDescription',
          },
        };

        return lbEventsInfo
          .find(query)
          .$promise.then(_fixDescription)
          .then(_fixRelatedInstruments)
          .then(function (res) {
            res.forEach(function (event) {
              event.type = event.eventType;
              delete event.eventType;
            });
            gEventsList = res;
          });
      } else {
        var deferred = $q.defer();
        deferred.resolve([]);
        return deferred.promise;
      }
    }

    /**
     *
     * @param {*} results
     * @return {any}
     */
    function _fixDescription(results) {
      results.forEach(function (result) {
        if (result.eventsDescription) {
          result.description = result.eventsDescription.description || '';
          delete result.eventsDescription;
        } else {
          result.description = '';
        }
      });

      return results;
    }

    /**
     *
     * @param {*} results
     * @return {any}
     */
    function _fixRelatedInstruments(results) {
      if (btSettingsService.isLinkDataService()) {
        results.forEach(function (result) {
          if (result.relatedInstruments) {
            result.relatedInstruments = result.relatedInstruments.filter(function (i) {
              return ['OIL', 'West Texas Oil'].indexOf(i) === -1;
            });
          }
        });
      }

      return results;
    }

    /**
     *
     * @return {*}
     */
    function getEventsList() {
      return gEventsList;
    }

    /**
     * Get event by id
     * @param {Number} id - event id
     * @return {btRawEvent}
     */
    function getEventById(id) {
      return gEventsList.filter(function (row) {
        return row['id'] === id;
      })[0];
    }

    /**
     *
     * @param {*} eventId
     * @param {*} instrumentName
     * @return {*}
     */
    function getEventInfo(eventId, instrumentName) {
      // find event description
      var eventInfo = gEventsList.filter(function (row) {
        return row['id'] == eventId;
      })[0];

      return addCurrencyInfo(eventInfo, instrumentName);
    }

    /**
     *
     * @param {*} eventInfo
     * @param {*} instrumentName
     * @return {*}
     */
    function addCurrencyInfo(eventInfo, instrumentName) {
      // add currency information
      if (eventInfo) {
        if (instrumentName) {
          eventInfo.currencyDirection = btCurrenciesService.getCurrencyDirection(instrumentName, eventInfo.currency);
        }
        eventInfo.currencyFlag = btCurrenciesService.getCurrencyFlag(eventInfo.currency);
      }
      return eventInfo;
    }

    /**
     *
     * @param {*} data
     * @return {angular.IPromise<*>}
     */
    function loadReleases(data) {
      return lbRows.find({
        filter: {
          where: {
            and: [{ eventId: data.eventId }, { time: { between: [data.from, data.till] } }],
          },
          order: 'time DESC',
          limit: data.limit,
        },
      }).$promise;
    }

    /**
     * This function runs backtesting for releases.
     *
     * @param {Object} data
     * @return {angular.IPromise<*>}
     */
    function getBackTesterCharts(data) {
      if (window.btSettings.BT_BACKTESTING_VERSION === '1') {
        return runBacktestingV1WrapperCharts(data);
      } else {
        return lbRows.getGraphsOfRows({ data: data }).$promise;
      }
    }

    /**
     * This function runs backtesting for releases.
     *
     * @param {Object} data
     * @return {angular.IPromise<*>}
     */
    function getBackTesterMoments(data) {
      if (window.btSettings.BT_BACKTESTING_VERSION === '1') {
        return runBacktestingV1WrapperMoments(data);
      } else {
        return lbRows.getGraphsOfMoments({ data: data }).$promise;
      }
    }

    /**
     *
     * @param {*} type
     * @param {*} moments
     * @param {*} instruments
     * @param {*} params
     * @return {any}
     */
    function runBacktestingV1(type, moments, instruments, params) {
      var query = { type: type, moments: moments, instruments: instruments, params: params };
      return lbRows.backtestingV1(query).$promise;
    }

    /**
     *
     * @param {*} data
     * @return {angular.IPromise<{getGraphsOfRows: {charts, total, insights, insights2, stats, stats2}}>}
     */
    function runBacktestingV1WrapperCharts(data) {
      var relatedInstruments = [];
      var query1 = {
        eventId: data.eventId,
        from: data.from,
        till: data.till,
        min: data.minRowStrength,
        max: data.maxRowStrength,
      };
      return lbRows
        .search(query1)
        .$promise.then(function (response) {
          var releases = response.results;
          var moments = releases.map(function (value) {
            return value.time;
          });
          relatedInstruments = releases[0] ? releases[0].eventsInfo.relatedInstruments : [];
          relatedInstruments = relatedInstruments.map(function (value) {
            var oandaSymbol = btInstrumentsService.convertBrokerName(value, 'oanda');
            if (oandaSymbol) {
              return oandaSymbol + ':OANDA';
            } else {
              return value;
            }
          });

          return getInstrumentsATR(relatedInstruments, data.interval, data.date).then(function (values) {
            data.atr = values;
            var query2 = { type: 'Release@', moments: moments, instruments: relatedInstruments, params: data };
            return lbRows.backtestingV1(query2).$promise.then(function (tests) {
              return [tests, releases];
            });
          });
        })
        .then(function (results) {
          return [convertBacktestingResponse(relatedInstruments, results[0], results[1]), results[1]];
        })
        .then(function (results) {
          results[0].insights.forEach(function (idea) {
            idea.market = btInstrumentsService.convertComplexName2BTName(idea.market);
          });
          results[0].insights2.forEach(function (idea) {
            idea.market = btInstrumentsService.convertComplexName2BTName(idea.market);
          });
          Object.keys(results[0].charts).forEach(function (item) {
            results[0].charts[item].graphs.forEach(function (chart) {
              var instrument = btInstrumentsService.getInstrumentByComplexSymbol(chart.chartInstrument);
              chart.chartInstrument = instrument.btName;
              if ((window.btSettings.BT_BACKTESTING_VERSION || '0') === '0') {
                if (chart.chartName) {
                  var names = chart.chartName.split(':');
                  names.pop();
                  names.push(instrument.histData);
                  chart.chartName = names.join(':');
                }
              }
            });
          });
          return { getGraphsOfRows: results[0], releases: results[1] };
        });
    }

    /**
     *
     * @param {*} data
     * @return {angular.IPromise<{getGraphsOfMoments: {charts, total, insights, insights2, stats, stats2}}>}
     */
    function runBacktestingV1WrapperMoments(data) {
      return getInstrumentsATR(data.relatedInstruments, data.interval, data.date)
        .then(function (value) {
          data.atr = value;
          var query = {
            type: data.type,
            moments: data.moments,
            instruments: data.relatedInstruments,
            params: data,
          };
          return lbRows.backtestingV1(query).$promise;
        })
        .then(function (value) {
          return convertBacktestingResponse(data.relatedInstruments, value);
        })
        .then(function (value) {
          return { getGraphsOfMoments: value };
        });
    }

    /**
     *
     * @param {string[]} instruments - list of related instruments
     * @param {object} response - backtesting response
     * @param {btRelease[]} [releases] - list of releases
     * @return {*}
     */
    function convertBacktestingResponse(instruments, response, releases) {
      if (!response) return { charts: {}, total: 0, insights: [], insights2: [], stats: {}, stats2: {} };

      var stats = {};
      var ideas = [];
      var charts = {};
      instruments.forEach(function (symbol) {
        if (response.result[symbol]) {
          stats[symbol] = response.result[symbol].summary;
          stats[symbol].name = symbol;
          stats[symbol].atr = response.result[symbol].atr;
          stats[symbol].hasOneSuccess = response.result[symbol].idea !== null;
          stats[symbol].analysis = response.result[symbol].analysis;

          if (response.result[symbol].idea) {
            ideas.push(response.result[symbol].idea);
          }

          response.result[symbol].moments.forEach(function (moment) {
            if (charts[moment.release.id] === undefined) {
              charts[moment.release.id] = { row: getReleaseInfo(moment, releases), graphs: [] };
            }

            charts[moment.release.id].graphs.push({
              chartData: moment.chart.values,
              chartName: moment.chart.name,
              chartMeta: moment.chart.meta,
              // chartTrade: value.trade,
              chartInstrument: symbol,
            });
          });
        }
      });
      return {
        charts: charts,
        total: response.total,
        insights: ideas,
        insights2: ideas,
        stats: stats,
        stats2: stats,
      };
    }

    /**
     *
     * @param {*} moment
     * @param {*} releases
     * @return {any}
     */
    function getReleaseInfo(moment, releases) {
      if (!releases) {
        return moment.release;
      } else {
        var release = releases.filter(function (release) {
          return release.time === moment.release.time;
        })[0];

        return release || moment.release;
      }
    }

    /**
     *
     * @param {*} symbols
     * @param {*} interval
     * @param {*} time
     * @return {angular.IPromise<any>}
     */
    function getInstrumentsATR(symbols, interval, time) {
      console.time('Get ATR');
      var promises = symbols.map(function (symbol) {
        return btRequestService.getATR(symbol, interval, time);
      });

      return $q.all(promises).then(function (results) {
        var atr = {};

        results.forEach(function (value, i) {
          atr[symbols[i]] = value.atr;
        });

        console.timeEnd('Get ATR');
        return atr;
      });
    }
  }
})();
