(function btVolatilityDeviationClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * Display volatility deviation
     *
     * @ngdoc controller
     * @name btVolatilityDeviation
     * @memberOf dashboard
     */
    .directive('btVolatilityDeviation', [
      '$templateCache',
      'btChartService',
      function ($templateCache, btChartService) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            data: '=',
            collapsed: '@',
            name: '@',
          },

          template: $templateCache.get('dashboard/directives/common/bt-volatility-deviation.html'),
          controller: [
            '$scope',
            function ($scope) {
              /**
               *
               * @param {*} a
               * @param {*} b
               * @return {*}
               */
              function reduceFunc(a, b) {
                return a + b;
              }

              $scope.generateDeviations = function (scope) {
                console.log('data:', scope.data);

                var result = {};
                for (var i = 0; i < scope.data.length; i++) {
                  for (var j = 0; j < scope.data[i].graphs.length; j++) {
                    var name_short = null;
                    if (scope.data[i].graphs[j].chartName != null)
                      name_short = scope.data[i].graphs[j].chartName.split(':')[2];
                    else continue;
                    var name = scope.data[i].graphs[j].displayName;
                    var meta = scope.data[i].graphs[j].chartMeta;
                    result[name] = result[name] || {
                      name: 'none',
                      name_short: 'none',
                      d1: [],
                      d2: [],
                      d3: [],
                      d4: [],
                      d5: [],
                    };

                    result[name].name = name;
                    result[name].name_short = name_short;
                    if (meta != null && meta.atr_deviation != null) {
                      result[name].d1.push(meta.atr_deviation.first_5);
                      result[name].d2.push(meta.atr_deviation.second_10);
                      result[name].d3.push(meta.atr_deviation.third_15);
                      result[name].d4.push(meta.atr_deviation.forth_30);
                      result[name].d5.push(meta.atr_deviation.fifth_30);
                    }
                  }
                  scope.values = {};

                  var avg3 = [];
                  var names = [];
                  var length = 0;
                  for (var res in result) {
                    length++;
                    scope.values[res] = scope.values[res] || {
                      name: result[res].name,
                      d1: 0,
                      d2: 0,
                      d3: 0,
                      d4: 0,
                      d5: 0,
                    };
                    var d1 = result[res].d1.reduce(reduceFunc, 0) / result[res].d1.length;
                    var d2 = result[res].d2.reduce(reduceFunc, 0) / result[res].d2.length;
                    var d3 = result[res].d3.reduce(reduceFunc, 0) / result[res].d3.length;
                    var d4 = result[res].d4.reduce(reduceFunc, 0) / result[res].d4.length;
                    var d5 = result[res].d5.reduce(reduceFunc, 0) / result[res].d5.length;
                    scope.values[res].d1 = d1.toFixed(2);
                    scope.values[res].d2 = d2.toFixed(2);
                    scope.values[res].d3 = d3.toFixed(2);
                    scope.values[res].d4 = d4.toFixed(2);
                    scope.values[res].d5 = d5.toFixed(2);
                    avg3.push(parseFloat(((d1 + d2 + d3) / 3).toFixed(2)));
                    if (result[res].name_short != null) names.push(result[res].name_short);
                    else names.push(result[res].name);
                  }

                  scope.chartHeight = length * 30 + 'px';
                  scope.graph = btChartService.getDeviation(names.reverse(), avg3.reverse());
                }
              };

              $scope.generateDeviations($scope);

              $scope.$watch('data', function () {
                $scope.generateDeviations($scope);
              });
            },
          ],
        };
      },
    ]);
})();
