(function correlationMeterCtrlClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'correlationMeterCtrlClosure';

  angular.module('dashboard').controller('correlationMeterCtrl', correlationMeterCtrl);

  correlationMeterCtrl.$inject = ['$scope', '$interval'];

  /**
   * Correlation Meter Controller (Uri's widget controller)
   *
   * @ngdoc controller
   * @name correlationMeterCtrl
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IIntervalService} $interval
   */
  function correlationMeterCtrl($scope, $interval) {
    console.log('Running correlationMeterCtrl');

    $scope.threshold = 0.2;

    var randomNumber = function getNonZeroRandomNumber() {
      var random = Math.floor(Math.random() * 199) - 99;
      if (random === 0) return randomNumber();
      return random;
    };

    var intervalPromise = $interval(correlationFunc, 15000);

    $scope.stopRandom = stopRandom;

    $scope.$on('$destroy', correlationMeterCtrlOnDestroy);

    /**
     *
     */
    function correlationMeterCtrlOnDestroy() {
      $interval.cancel(intervalPromise);
      if (gDebug) console.log(gPrefix, 'Controller correlationMeterCtrl was destroyed!');
    }

    /**
     *
     */
    function correlationFunc() {
      if (gDebug) console.log(gPrefix, 'Interval was called (correlationFunc)');
      $scope.sampleData = [
        {
          correlation: randomNumber() / 100,
          getting: 1,
        },
      ];
    }

    /**
     *
     */
    function stopRandom() {
      $interval.cancel(intervalPromise);
    }
  }
})();
