/**
 * Created by Sergey Panpurin on 9/29/2016.
 */

(function btBackTesterStatsClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc directive
     * @name btBackTesterStats
     * @memberOf dashboard
     * @description
     *  This directive shows back-testing statistic
     */
    .directive('btBackTesterStats', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            stats: '=',
            current: '=',
            total: '=',
            hasSuccess: '=',
            collapsed: '@',
          },
          template: $templateCache.get('dashboard/directives/back-tester/bt-back-tester-stats.html'),
        };
      },
    ]);
})();
