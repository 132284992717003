'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btDatePicker
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btDatePicker', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          date: '=',
          time: '=',
        },

        template: $templateCache.get('dashboard/directives/common/bt-date-picker.html'),
      };
    },
  ]);
