/**
 * Created by Sergey Panpurin on 4/5/2017.
 */

/**
 * Created by Sergey Panpurin on 10/18/2016.
 */
(function btHistogramClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc directive
     * @name btHistogram
     * @memberOf dashboard
     * @description
     *  This directive show additional information
     */
    .directive('btHistogram', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            values: '=',
            deviation: '=?',
          },
          template: $templateCache.get('dashboard/directives/common/bt-histogram.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.deviation = $scope.deviation || false;
            },
          ],
        };
      },
    ]);
})();
