'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btNumberPicker
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btNumberPicker', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: {
          label: '@',
          minValue: '@?min',
          maxValue: '@?max',
        },
        template: $templateCache.get('dashboard/directives/common/bt-number-picker.html'),
      };
    },
  ]);
