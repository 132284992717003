(function btInfoHubCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btInfoHubCtrl', btInfoHubCtrl);

  btInfoHubCtrl.$inject = [
    '$scope',
    '$timeout',
    '$analytics',
    'btSettingsService',
    'btDeviceService',
    'btShareScopeService',
  ];

  /**
   * Info hub controller
   *
   * @ngdoc controller
   * @name btInfoHubCtrl
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ITimeoutService} $timeout
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IDeviceService} btDeviceService
   * @param {ecapp.IShareScopeService} btShareScopeService
   */
  function btInfoHubCtrl($scope, $timeout, $analytics, btSettingsService, btDeviceService, btShareScopeService) {
    console.log('Running btInfoHubCtrl');

    $scope.hasWidget = 1;
    // $scope.isBetterTrader = btSettingsService.domain === 'bettertrader';
    $scope.isBigBrainBank = btSettingsService.domain === 'bigbrainbank';
    $scope.isOptimus = btSettingsService.domain === 'optimusfutures';
    $scope.height = '600px';

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    activate();

    /**
     *
     */
    function onViewLoaded() {
      onViewOpen();
    }

    /**
     *
     */
    function onViewEnter() {
      onViewOpen();
    }

    /**
     *
     */
    function onViewOpen() {
      $timeout(function () {
        $analytics.eventTrack('open', { category: 'dashboard', label: 'info-hub' });
      }, 1000);
    }

    /**
     *
     */
    function activate() {
      btShareScopeService.wait().then(function () {
        if (btDeviceService.shouldShowMobileAlert()) {
          btDeviceService.sendGetMobileAlert();
          btShareScopeService.setDeviceAlertToTrue();
          btShareScopeService.updateProfileSettingsFromFieldsList(['deviceAlert']).catch(function (reason) {
            console.error(reason);
          });
        }
      });
    }
  }
})();
