/**
 * Created by Itay on 7/21/2016.
 */

'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btReleaseHeader
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btReleaseHeader', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('dashboard/directives/back-tester/bt-release-header.html'),
        controller: [
          '$scope',
          'btDateService',
          function ($scope, btDateService) {
            var chartsDate = btDateService.getDateFromRow($scope);
            if ($scope.type === 'moment') {
              $scope.dateText = btDateService.getDayName(chartsDate) + ' ' + btDateService.getClockText(chartsDate);
            } else {
              $scope.dateText = btDateService.getDayName(chartsDate);
            }
          },
        ],
      };
    },
  ]);
