// cSpell:words Strengh

(function btSentimentCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btSentimentCtrl', btSentimentCtrl);

  btSentimentCtrl.$inject = [
    '$scope',
    '$q',
    '$ionicLoading',
    '$timeout',
    'btRequestService',
    'CacheFactory',
    'btChartService',
    'btSharedData',
    'btEventService',
    'btPubNubService',
  ];

  /**
   * This is sentiment page controller. It handles all process of snapshot receiving: from user form to
   * calculation snapshot stats.
   *
   * @ngdoc controller
   * @name btSentimentCtrl
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.IQService} $q - promise interface (used once)
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {angular.ITimeoutService} $timeout - wrapper for window.setTimeout (used once)
   * @param {ecapp.IRequestService} btRequestService - access to BT WEB API
   * @param {angular.ICacheFactoryService} CacheFactory - useful replacement for the Angular 1 $cacheFactory
   * @param {ecapp.IChartService} btChartService - plotting charts using ZingChart
   * @param {ecapp.ISharedData} btSharedData - share row selected in economical calendar
   * @param {ecapp.IEventService} btEventService - information about events and releases
   * @param {ecapp.IPubNubService} btPubNubService - PubNub interface to send message to TradeStation
   */
  function btSentimentCtrl(
    $scope,
    $q,
    $ionicLoading,
    $timeout,
    btRequestService,
    CacheFactory,
    btChartService,
    btSharedData,
    btEventService,
    btPubNubService
  ) {
    console.log('Running btSentimentCtrl');

    // List of variables on scope:
    //  $scope.form - form data
    //  $scope.eventInfo - information about event
    //  $scope.releases - information about release all releases TODO: Move to controller
    //  $scope.metaData TODO: Move to controller
    //  $scope.chartJSON TODO: Move to controller
    //  $scope.cur - information about current selected snapshot
    //  $scope.stats - snapshots stats
    //  $scope.prevEventId - id of previous event (didn't used now)

    // List of fields of releases array:
    //  'id', 'eventId', 'time',
    //  'revision', 'previous', 'actual', 'forecast',
    //  'revisionStrength','oldStrength','releaseStrength',
    //  'currency_direct',
    //  'reaction', 'reaction_scale', 'sentiment', 'thr_reaction', 'thr_strength'

    // remove onShareRowInfo callback on controller destroy
    $scope.$on('$destroy', btSentimentCtrlOnDestroy);

    // +++ Initialize variables
    $scope.hasWidget = 1;
    $scope.sentimentFilter = 'all';
    $scope.strengthFilter = 'all';

    var ctrl = this;

    // default values
    var defaultStrengthThreshold = 10;
    var defaultReactionThreshold = 0.1;
    var defaultBefore = 15;
    var defaultAfter = 90;
    var defaultResultLimit = 20;
    var defaultInstrumentName = 'SPXUSD';

    $scope.publishPubNub = publishPubNub;
    $scope.resetForm = resetForm;
    $scope.saveJSON = saveJSON;
    $scope.filterSnapshot = filterSnapshot;
    $scope.displaySnapshot = displaySnapshot;
    $scope.loadSnapshots = loadSnapshots;

    this.resetReleases = resetReleases;
    this.resetCharts = resetCharts;
    this.initData = initData;
    this.filterStrength = filterStrength;
    this.getReleases1 = getReleases1;
    this.filterSentiment = filterSentiment;

    $scope.resetForm();
    this.resetReleases();
    this.resetCharts();
    this.initData();

    // prepare events list
    btEventService.init().then(function postInitEventService() {
      $scope.eventsList = btEventService.getEventsList();
    });

    btSharedData.addCallback('rowInfo', onShareRowInfo);

    $scope.counter = 0;
    $scope.$watch(
      'cur.index',
      function watchCurIndex(newValue) {
        $scope.displaySnapshot(newValue);
        // console.log(" -------------  ------------- --------------- " + $scope.counter);
        $scope.counter++;
      },
      true
    );

    // +++ Function section

    /**
     *
     */
    function btSentimentCtrlOnDestroy() {
      btSharedData.deleteCallback('rowInfo', onShareRowInfo);
      console.log('Controller btSentimentCtrl was destroyed!');
    }

    /**
     * This function publish information about sentiment to PubNub channel for TradeStation.
     * It is connected to onClick event of button near the sentiment title.
     *
     * @ngdoc method
     * @name publishPubNub
     * @memberOf dashboard.btSentimentCtrl
     */
    function publishPubNub() {
      if ($scope.hasResults) {
        var data = {
          currency: $scope.eventInfo.currency,
          firstStrengh: $scope.cur.meta.release.oldStrength.toString(),
          insightStrengh: '0',
          direction: $scope.eventInfo.direction.toString(),
          name: $scope.eventInfo.id.toString(),
          type: '1',
          newStrength: $scope.cur.meta.release.releaseStrength.value.toString(),
          eventName: $scope.eventInfo.name,
          sentiment: $scope.cur.meta.release.sentiment.toString(),
          instrument: $scope.form.stock.name,
          delay: $scope.form.after.toString(),
          insightSurpriseStrengh: '0',
        };
        console.log(data);
        btPubNubService.devPublish('my_channel', data);
      }
    }

    /**
     * This function init or reset form data.
     * It is called during controller initialisation and connected to onClick event of reset button.
     *
     * @ngdoc method
     * @name resetForm
     * @memberOf dashboard.btSentimentCtrl
     */
    function resetForm() {
      $scope.form = {
        from: new Date(2010, 1 - 1, 1),
        till: new Date(),
        eventId: undefined,
        stock: { name: defaultInstrumentName },
        before: defaultBefore,
        after: defaultAfter,
        limit: defaultResultLimit,
        thr_strength: defaultStrengthThreshold,
        thr_reaction: defaultReactionThreshold,
        direction: 1,
        offset: 0,
      };
      $scope.eventInfo = {};
      // $scope.prevEventId = -1;
    }

    /**
     * This function init or reset releases information.
     * It is called during controller initialisation and after pressing on submit button before receiving of new
     * data.
     *
     * @ngdoc method
     * @name resetReleases
     * @memberOf dashboard.btSentimentCtrl
     */
    function resetReleases() {
      $scope.hasResults = false;
      $scope.releases = [];
    }

    /**
     * This function init or reset charts.
     * It is called during controller initialisation and after pressing on submit button before receiving of new
     * data.
     *
     * @ngdoc method
     * @name resetCharts
     * @memberOf dashboard.btSentimentCtrl
     */
    function resetCharts() {
      $scope.hasResults = false;
      $scope.metaData = [];
      $scope.chartJSON = [];
      $scope.cur = {
        index: -1,
        json: { title: { text: 'No Data', color: 'black' } },
        meta: { release: { oldStrength: 0, releaseStrength: { value: 0 }, revisionStrength: { value: 0 } } },
      };
      $scope.stats = { weak: 0, reg: 0, pos: 0, neg: 0 };
      $scope.sentimentFilter = 'all';
      $scope.strengthFilter = 'all';
    }

    /**
     * This function initialize demo (first view) data loaded from prepared json files.
     * Use saveJSON function to prepare data.
     * It is called during controller initialisation only.
     *
     * @ngdoc method
     * @name initData
     * @memberOf dashboard.btSentimentCtrl
     */
    function initData() {
      $.getJSON('sentiment_init.json', function (json) {
        $scope.metaData = json.metaData;
        $scope.chartJSON = json.chartJSON;
        $scope.eventInfo = json.eventInfo;
        $scope.releases = json.releases;
        $scope.filterSnapshot();

        $timeout(finishInitData, 200, false, json);
      });

      /**
       *
       * @param {*} json
       */
      function finishInitData(json) {
        $scope.hasResults = true;
        $scope.displaySnapshot($scope.cur.total - 1);
        $scope.stats = json.stats;
      }
    }

    /**
     * This function save current stage to json file. It can be used to display demo (first view) data.
     * For development only.
     *
     * @ngdoc method
     * @name saveJSON
     * @memberOf dashboard.btSentimentCtrl
     */
    function saveJSON() {
      var data = JSON.stringify({
        metaData: $scope.metaData,
        chartJSON: $scope.chartJSON,
        cur: $scope.cur,
        stats: $scope.stats,
        eventInfo: $scope.eventInfo,
        releases: $scope.releases,
      });

      var url = 'data:text/json;charset=utf8,' + encodeURIComponent(data);

      var win = window.realOpen();
      win.document.write(
        '<iframe src="' +
          url +
          '" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>'
      );

      // window.open(url, '_blank');
      // window.focus();
    }

    /**
     * This function filter snapshots using strength of release and sentiment. Order of filtering: strength, sentiment.
     * To prevent displaying of empty array function count number of result and disable option in number of result
     * equal to zero. Use params.update = true to reset sentiment filter to all then you change strength filter
     * otherwise you may have empty array. Reset filters to all then receive new data.
     *
     * @ngdoc method
     * @name filterSnapshot
     * @memberOf dashboard.btSentimentCtrl
     * @param {*} params
     */
    function filterSnapshot(params) {
      if (params && params.update === true) {
        $scope.sentimentFilter = 'all';
      }

      var ind = ctrl.filterStrength();
      ind = ctrl.filterSentiment(ind);

      $scope.cur.total = ind.length;
      $scope.cur.index = ind.length - 1;
      ctrl.snapshotIndices = ind;
    }

    /**
     * This function return indices of snapshots from $scope.chartJSON corresponding to releases with strength in
     * filter range and count number of another options.
     *
     * @ngdoc method
     * @name filterStrength
     * @memberOf dashboard.btSentimentCtrl
     * @return {any}
     */
    function filterStrength() {
      $scope.strengthIndices = { all: [], pos: [], neg: [] };

      // Error: bad filter name
      if (!$scope.strengthIndices.hasOwnProperty($scope.strengthFilter)) {
        return [];
      }

      // count number of snapshots for all filters
      $scope.metaData.forEach(function (val, i) {
        this.all.push(i);
        if (val.release.strength > 0) this.pos.push(i);
        if (val.release.strength < 0) this.neg.push(i);
      }, $scope.strengthIndices);

      return $scope.strengthIndices[$scope.strengthFilter];
    }

    /**
     * This function return indices of snapshots from $scope.chartJSON corresponding to releases with sentiment in
     * filter range and count number of another options. Use only snapshots in range of strength filter.
     *
     * @ngdoc method
     * @name filterSentiment
     * @memberOf dashboard.btSentimentCtrl
     * @function
     * @param {*} ind
     * @return {any}
     */
    function filterSentiment(ind) {
      $scope.sentimentIndices = { all: [], thr: [], reg: [], pos: [], neg: [] };

      // Error: bad filter name
      if (!$scope.sentimentIndices.hasOwnProperty($scope.sentimentFilter)) {
        return [];
      }

      // count number of snapshots for all filters
      $scope.metaData.forEach(function (val, i) {
        if (ind.indexOf(i) >= 0) {
          this.all.push(i);
          if (val.status !== 'weak') this.thr.push(i);
          if (val.status === 'reg') this.reg.push(i);
          if (val.status === 'pos') this.pos.push(i);
          if (val.status === 'neg') this.neg.push(i);
        }
      }, $scope.sentimentIndices);

      return $scope.sentimentIndices[$scope.sentimentFilter];
    }

    /**
     * This function get releases corresponding to the data of user form.
     *
     * @ngdoc method
     * @name getReleases1
     * @memberOf dashboard.btSentimentCtrl
     * @function
     * @param {Object} data of user form
     * @return {angular.IPromise<*>}
     */
    function getReleases1(data) {
      console.log('Receive releases...'); // TODO: Delete

      ctrl.resetReleases();

      return btEventService.loadReleases(data).then(function postLoadRelease(res) {
        console.log('Got ' + res.length); // TODO: Delete

        angular.forEach(
          res,
          function collectRelease(release) {
            this.push(release.toJSON());
          },
          $scope.releases
        );

        $scope.releases.reverse();
      });
    }

    /**
     * This function get snapshots for each release and calculate stats.
     *
     * @ngdoc method
     * @name loadSnapshots
     * @memberOf dashboard.btSentimentCtrl
     * @function
     * @param {Boolean} isValidForm - flag is form valid
     */
    function loadSnapshots(isValidForm) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Loading Snapshots...</p>' });

      if (!isValidForm) {
        $ionicLoading.hide();
        alert('Please fill in all the required fields correctly');
        return;
      }

      var res = btEventService.getEventInfo($scope.form.eventId, $scope.form.stock.name);

      if (res === null) {
        $ionicLoading.hide();
        alert("Can't find event data");
        return;
      }

      $scope.eventInfo = res;
      $scope.form.direction = $scope.eventInfo.currencyDirection || 1;

      console.log('Load snapshots'); // TODO: Delete

      var data = {};
      //collect data from the form and change the time format for to seconds.
      for (var key in $scope.form) {
        if ($scope.form.hasOwnProperty(key)) {
          if (key === 'from' || key === 'till') data[key] = Math.round($scope.form[key].getTime() / 1000.0);
          else data[key] = $scope.form[key];
        }
      }
      console.log(data); // TODO: Delete

      // TODO: Rename to getReleasesCharts and move all code inside
      // Get list of releases and receive market data
      var promise_stock = [];
      ctrl.getReleases1(data).then(function postGetReleases() {
        data.times = [];
        data.strengths = [];
        $scope.releases.forEach(function (element) {
          this.times.push(element.time);
          //noinspection JSUnresolvedVariable
          this.strengths.push(Math.round((element.oldStrength || 0) * 100));
        }, data);

        promise_stock = btRequestService.getReleaseDataList(data);

        console.log('Receive market data ' + $scope.form.stock.name); // TODO: Delete

        $q.all(promise_stock)
          .then(function processPromiseStock(data0) {
            ctrl.resetCharts();

            var data = [];
            for (var i = 0; i < data0.length; i++) {
              data = data.concat(data0[i]);
            }

            angular.forEach(data, function processMetaData(row, key) {
              if (row.values && row.values.length > 0) {
                var basePrice = 0;

                if (row.meta && !row.meta.error) {
                  var meta = row.meta;
                  // meta.text = $sce.trustAsHtml(meta.text);
                  meta.release = $.extend({}, $scope.releases[key], meta['release']);
                  console.log(meta.release); // TODO: Delete
                  basePrice = meta['prices']['base'];
                  if (meta.release.sentiment === undefined) {
                    $scope.stats.weak += 1;
                    meta.status = 'weak';
                    meta.statusText = 'Weak strength or reaction';
                  } else if (meta.release.sentiment > 0) {
                    $scope.stats.pos += 1;
                    meta.status = 'pos';
                    meta.statusText = 'Positive sentiment';
                  } else if (meta.release.sentiment < 0) {
                    $scope.stats.neg += 1;
                    meta.status = 'neg';
                    meta.statusText = 'Negative sentiment';
                  } else {
                    $scope.stats.reg += 1;
                    meta.status = 'reg';
                    meta.statusText = 'Regular behavior';
                  }
                  meta.symbol = row.symbol;
                  $scope.metaData.push(meta);
                } else {
                  $scope.metaData.push({
                    symbol: row.symbol,
                    release: $scope.releases[key],
                    status: 'weak',
                    statusText: 'Too little data',
                  });
                  $scope.stats.weak += 1;
                }

                var range = btChartService.getBound(row.values);
                $scope.chartJSON.push(
                  btChartService.buildStockChart(null, range, row.values, row.timestamp * 1000, basePrice)
                );
              } else {
                console.log('Bad data!'); // TODO: Delete
                console.log(row); // TODO: Delete check
              }
            });

            $scope.filterSnapshot();

            if ($scope.cur.total > 0) {
              $scope.hasResults = true;
              // setTimeout(function(){
              // console.log("------         TEST         -------");
              $scope.displaySnapshot($scope.cur.total - 1);
              // }, 200);
            }

            $ionicLoading.hide();
          })
          .catch(function (error) {
            $ionicLoading.hide();
            console.log('ErrorLog ' + error);
          });
      });
    }

    /**
     * This function prepare data to display selected snapshot.
     *
     * @ngdoc method
     * @name displaySnapshot
     * @memberOf dashboard.btSentimentCtrl
     * @param {Number} index - index of snapshot
     */
    function displaySnapshot(index) {
      if ($scope.hasResults && index >= 0) {
        // $scope.cur.index = index;
        $scope.cur.json = $scope.chartJSON[ctrl.snapshotIndices[index]];
        $scope.cur.meta = $scope.metaData[ctrl.snapshotIndices[index]];
      }
    }

    // $scope.countFilteredSnapshot = function countFilteredSnapshot() {
    //   return ctrl.snapshotIndices.length;
    // };

    /**
     *
     * @param {*} rowInfo
     * @param {*} opts
     */
    function onShareRowInfo(rowInfo, opts) {
      void opts;
      if ($scope.$tabSelected) {
        $scope.form.eventId = rowInfo.eventsInfo.id;
      }
    }
  }
})();
