(function btPreMainCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btPreMainCtrl', btPreMainCtrl);

  btPreMainCtrl.$inject = ['$scope', 'btSettingsService'];

  /**
   * Pre main controller
   *
   * @ngdoc controller
   * @name btPreMainCtrl
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btPreMainCtrl($scope, btSettingsService) {
    console.log('Running btPreMainCtrl');

    $scope.isBetterTrader = btSettingsService.domain === 'bettertrader';
    $scope.isOptimus = btSettingsService.domain === 'optimusfutures';

    $scope.splashLogoSrc = btSettingsService.getAssets('splash-logo-src');
    $scope.splashLogoAlt = btSettingsService.getAssets('splash-logo-alt');

    $scope.optimusLearnMoreHref = btSettingsService.getAssets('learn-more-href');
    $scope.openAccountHref = btSettingsService.getAssets('open-account-href');
    $scope.isVideoOpen = false;

    $scope.openVideo = openVideo;

    /**
     *
     */
    function openVideo() {
      $scope.isVideoOpen = !$scope.isVideoOpen;
    }
  }
})();
