(function btStrengthPickerClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc directive
     * @name btStrengthPicker
     * @memberOf dashboard
     * @description
     *  This directive is the strength picker for the dashboard module
     */
    .filter('percentage', [
      '$filter',
      function ($filter) {
        return function (input, decimals) {
          return input * 100 + '%';
        };
      },
    ])
    .directive('btStrengthPicker', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            btLabel: '=',
            minStrength: '=',
            maxStrength: '=',
            defaultButton: '=',
          },
          template: $templateCache.get('dashboard/directives/back-tester/bt-strength-picker.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.setSliderValues = function ($event, min, max) {
                $scope.minStrength = min;
                $scope.maxStrength = max;
                $scope.defaultButton = $event.currentTarget.name;
              };
            },
          ],
        };
      },
    ]);
})();
