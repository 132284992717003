/**
 * Created by edwin on 6/20/16.
 */

(function btDebugMarkerClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc controller
     * @name btDebugMarker
     * @memberOf dashboard
     * @description
     *  Debug marker
     */
    .directive('btDebugMarker', [
      function () {
        return {
          restrict: 'A',
          scope: false,
          controller: [
            '$scope',
            function ($scope) {
              $scope.debugThis = '!';
            },
          ],
        };
      },
    ]);
})();
