/**
 * Created by Sergey Panpurin on 12/26/2016.
 */

(function btBackTesterInsightClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc directive
     * @name btBackTesterInsight
     * @memberOf dashboard
     * @description
     *  This directive shows trading insights in back-testing view
     */
    .directive('btBackTesterInsight', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            insight: '=',
          },
          template: $templateCache.get('dashboard/directives/back-tester/bt-back-tester-insight.html'),
          controller: ['$scope', '$ionicPopup', 'btInstrumentsService', btBackTesterInsightController],
        };
      },
    ]);

  /**
   *
   * @param {*} $scope
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   */
  function btBackTesterInsightController($scope, $ionicPopup, btInstrumentsService) {
    $scope.data = $scope.insight.insight.data;

    $scope.openTradeCard = function openTradeCard() {
      var popupHandler = $ionicPopup.show({
        template:
          '<h4>{{data.market}}</h4><bt-trading-card-a insight="insight" show-enter-price="false"></bt-trading-card-a>',
        cssClass: 'popup-trade-card',
        title: 'Trade card',
        // subTitle: 'Choose a way to share content',
        scope: $scope,
        buttons: [
          {
            text: 'Close',
            type: 'button-close',
            onTap: function () {
              popupHandler.close();
            },
          },
        ],
      });
    };

    if (btInstrumentsService.getBroker($scope.data.market) === 'OANDA') {
      var oandaSymbol = btInstrumentsService.getSymbol($scope.data.market);
      $scope.insight.market = btInstrumentsService.convertBTName(oandaSymbol, 'oanda');
      $scope.data.market = $scope.insight.market;
    }
  }
})();
