'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btChart
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btChart', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          symbol: '=',
          isFirst: '=',
          chartData: '=',
          chartName: '=',
          chartMeta: '=',
          chartTrade: '=',
          displayName: '=',
          isRealTime: '=?',
        },
        template: $templateCache.get('dashboard/directives/common/bt-chart.html'),
        controller: [
          '$scope',
          function ($scope) {
            $scope.isRealTime = $scope.isRealTime || false;

            $scope.collapsed = !$scope.isFirst;

            $scope.wasOpened = $scope.collapsed === false;

            $scope.collapse = function () {
              $scope.collapsed = !$scope.collapsed;

              if ($scope.collapsed === false) {
                $scope.wasOpened = true;
              }
            };

            $scope.undefinedInstrument = $scope.displayName.includes('undefined');

            $scope.$on('collapseGraphs', function (event, data) {
              console.log('On chart!');
              if (data.name === $scope.displayName) {
                $scope.collapsed = data.state;

                if (!data.state) {
                  $scope.wasOpened = true;
                }
              }
            });

            $scope.collapseAll = function (name) {
              $scope.$emit('requestCollapseGraphs', { name: name, state: !$scope.collapsed });
              console.log('Emit!');
            };
          },
        ],
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btReleaseChartsGroup
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btReleaseChartsGroup', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          event: '=',
          release: '=',
          type: '=',
          isRealTime: '=?',
        },
        template: $templateCache.get('dashboard/directives/common/bt-release-charts-group.html'),
        controller: [
          '$scope',
          '$state',
          'btStrengthService',
          'btRowProcessorService',
          'btWindowsSizeService',
          function ($scope, $state, btStrengthService, btRowProcessorService, btWindowsSizeService) {
            $scope.id = $scope.release.id;
            $scope.time = $scope.release.time;
            $scope.actual = $scope.release.actual;
            $scope.forecast = $scope.release.forecast;
            $scope.previous = $scope.release.previous;
            $scope.revision = $scope.release.revision;
            $scope.chartsList = $scope.release.graphs;
            $scope.releaseStrength = $scope.release.releaseStrength;
            $scope.revisionStrength = $scope.release.revisionStrength;
            $scope.oldReleaseStrength = $scope.release.oldReleaseStrength;

            $scope.isRealTime = $scope.isRealTime || false;

            if ($scope.releaseStrength != null) {
              $scope.releaseStrengthPercentage = btStrengthService.prepareStrength($scope.releaseStrength, $scope.time);
            }
            if ($scope.revisionStrength != null) {
              $scope.revisionStrengthPercentage = btStrengthService.prepareStrength(
                $scope.revisionStrength,
                $scope.time
              );
            }

            var checkIfDataToDisplay = function () {
              var dataInCharts = false;
              $scope.chartsList.forEach(function (chart) {
                dataInCharts += chart.chartName;
              });
              return dataInCharts;
            };

            $scope.dataToDisplay = checkIfDataToDisplay();

            $scope.openReleaseWindow = function () {
              var data = btRowProcessorService.getReleaseIdData($scope, $scope.event);
              var personalLink = $state.href('ecapp.app.main.detail', data, { absolute: false });
              btWindowsSizeService.openPopOutWindow($scope.event.name, personalLink);
            };
          },
        ],
      };
    },
  ]);
