'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btSnapshotNavigation
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btSnapshotNavigation', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btCurrent: '=',
          btTotal: '@',
        },
        template: $templateCache.get('dashboard/directives/common/bt-snapshot-navigation.html'),

        controller: [
          '$scope',
          function ($scope) {
            console.log('btSnapshotNavigation');
            console.log($scope);

            $scope.previous = function () {
              if ($scope.btCurrent > 0) {
                $scope.btCurrent--;
              }
            };

            $scope.next = function () {
              if ($scope.btCurrent < $scope.btTotal - 1) {
                $scope.btCurrent++;
              }
            };

            $scope.first = function () {
              $scope.btCurrent = 0;
            };

            $scope.last = function () {
              $scope.btCurrent = $scope.btTotal - 1;
            };
          },
        ],
      };
    },
  ]);
