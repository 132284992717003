'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btReleaseResultCardDetailed
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btReleaseResultCardDetailed', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          release: '=?',
          event: '=',
        },
        template: $templateCache.get('dashboard/directives/sentiment/bt-release-result-card-detailed.html'),
        controller: [
          '$scope',
          'btStrengthService',
          function ($scope, btStrengthService) {
            $scope.$watch('release', function () {
              if (null == $scope.release) return;
              if ($scope.release.releaseStrength != null) {
                $scope.releaseStrengthPercentage = btStrengthService.prepareStrength(
                  $scope.release.releaseStrength.value,
                  $scope.release.time
                );
              }
              if ($scope.release.revisionStrength != null) {
                $scope.revisionStrengthPercentage = btStrengthService.prepareStrength(
                  $scope.release.revisionStrength.value,
                  $scope.release.time
                );
              }
            });
          },
        ],
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btEventResultCard
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btEventResultCard', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          event: '=',
        },
        template: $templateCache.get('dashboard/directives/back-tester/bt-event-result-card.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btEventResultCard
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btMomentResultCard', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          moment: '=',
        },
        template: $templateCache.get('dashboard/directives/back-tester/bt-moment-result-card.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btReleaseResultCard
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btReleaseResultCard', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          release: '=',
          event: '=',
        },
        controller: [
          '$scope',
          'btStrengthService',
          function ($scope, btStrengthService) {
            $scope.$watch('release', function () {
              if (null == $scope.release) return;
              if ($scope.release.releaseStrength != null) {
                $scope.releaseStrengthPercentage = btStrengthService.prepareStrength(
                  $scope.release.releaseStrength.value,
                  $scope.release.time
                );
              }
              if ($scope.release.revisionStrength != null) {
                $scope.revisionStrengthPercentage = btStrengthService.prepareStrength(
                  $scope.release.revisionStrength.value,
                  $scope.release.time
                );
              }
            });
          },
        ],
        template: $templateCache.get('dashboard/directives/sentiment/bt-release-result-card.html'),
      };
    },
  ]);
