/**
 * Created by Sergey on 8/6/18.
 */

(function btTradingPlatformCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btTradingPlatformCtrl', btTradingPlatformCtrl);

  btTradingPlatformCtrl.$inject = [
    '$scope',
    '$sce',
    '$timeout',
    '$analytics',
    '$state',
    'btTradingPlatformService',
    'btMarketing',
    'btSettingsService',
    'btRestrictionService',
  ];

  /**
   * Controller for about us page.
   *
   * @ngdoc controller
   * @name ecapp.btTradingPlatformCtrl
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {angular.ISCEService} $sce - ?
   * @param {angular.ITimeoutService} $timeout - ?
   * @param {ext.IAnalyticsService} $analytics - ?
   * @param {angular.ui.IStateService} $state - ?
   * @param {ecapp.ITradingPlatformService} btTradingPlatformService - ?
   * @param {ecapp.IMarketing} btMarketing - ?
   * @param {ecapp.ISettingsService} btSettingsService - ?
   * @param {ecapp.IRestrictionService} btRestrictionService - ?
   */
  function btTradingPlatformCtrl(
    $scope,
    $sce,
    $timeout,
    $analytics,
    $state,
    btTradingPlatformService,
    btMarketing,
    btSettingsService,
    btRestrictionService
  ) {
    console.log('Running btTradingPlatformCtrl');

    $scope.platforms = [];
    $scope.brokers = [];

    $scope.stage = 'loading';
    // Platform identifier
    $scope.platform = null;
    // Platform or broker object
    $scope.broker = null;
    // Trading Platform URL
    $scope.brokerUrl = null;

    $scope.hasTrading = btSettingsService.hasFeature('trading');
    $scope.isDesktop = !(window.isMobileWeb || window.isMobile);
    $scope.tradingPlatform = btTradingPlatformService.status;

    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    $scope.openIntroduction = openIntroduction;
    $scope.connectPlatform = connectPlatform;
    $scope.connectBroker = connectBroker;
    $scope.openAccount = openAccount;
    $scope.openBenefits = openBenefits;
    $scope.disconnectTradingPlatform = disconnectTradingPlatform;
    $scope.openTutorial = openTutorial;

    btTradingPlatformService.addDisconnectListener(onPlatformDisconnect);

    activate();

    /**
     * Activate controller
     */
    function activate() {
      btTradingPlatformService.initialize().then(function () {
        $scope.platforms = btTradingPlatformService.getPlatforms();

        if (btTradingPlatformService.status.connected) {
          $scope.stage = 'trading';
          $scope.platform = btTradingPlatformService.status.platform;
          $scope.broker = btTradingPlatformService.status.broker;
          $scope.variant = btTradingPlatformService.status.variant;

          var url = btTradingPlatformService.getPlatformUrl($scope.broker, $scope.variant);
          $scope.brokerUrl = $sce.trustAsResourceUrl(url);
        } else {
          $scope.stage = 'platform';
        }
      });
    }

    /**
     * React on view loaded
     */
    function onViewLoaded() {
      onViewOpen();
    }

    /**
     * React on view entered
     */
    function onViewEnter() {
      onViewOpen();
    }

    /**
     * React on view loaded or entered
     */
    function onViewOpen() {
      $timeout(function () {
        $analytics.eventTrack('open', { category: 'dashboard', label: 'trading-platform' });
      }, 1000);
    }

    /**
     * Open introduction (select platform script)
     */
    function openIntroduction() {
      $scope.stage = 'platform';
      $scope.platform = null;
      $scope.broker = null;
      $scope.brokerUrl = null;
    }

    /**
     * Tries to connect platform
     *
     * @param {btTradingPlatformObject} platform - platform object
     * @param {string} [variant] - platform variant
     */
    function connectPlatform(platform, variant) {
      if (platform.brokers) {
        // go to select broker screen
        $scope.stage = 'broker';
        $scope.platform = platform.id;
        $scope.broker = null;
        $scope.brokerUrl = null;
        $scope.brokers = platform.brokers.filter(function (broker) {
          return broker.enabled;
        });
      } else {
        if (btRestrictionService.hasFeature('trading-platform')) {
          // connect selected platform
          btTradingPlatformService.connect(platform.id, platform, variant).then(function (result) {
            if (result.confirmed) {
              $scope.stage = 'trading';
              $scope.platform = platform.id;
              $scope.broker = platform;
              $scope.brokerUrl = $sce.trustAsResourceUrl(result.url);
            }
          });
        } else {
          btRestrictionService.showUpgradePopup('trading-platform');
        }
      }
    }

    /**
     * Tries to open trading account.
     *
     * @param {btTradingPlatformObject} broker - platform object
     */
    function openAccount(broker) {
      if (broker.practice) {
        alert('Account is not required. Just launch ' + broker.name + ' and start practice.');
      } else if (typeof broker.account === 'string') {
        window.open(broker.account, '_system');
      } else {
        $state.go('ecapp.app.open-account', {
          platform: $scope.platform ? $scope.platform : broker.id,
          broker: broker.id,
        });
      }
    }

    /**
     * Open benefits.
     *
     * @param {btTradingPlatformObject} broker - ?
     */
    function openBenefits(broker) {
      btMarketing.askQuestion('Interesting in ' + broker.name + ' benefits:');
    }

    /**
     * Connect broker of selected platform
     * @param {btTradingPlatformObject} broker - broker object
     */
    function connectBroker(broker) {
      // connect selected platform amd broker
      btTradingPlatformService.connect($scope.platform, broker).then(function (result) {
        if (result.confirmed) {
          $scope.stage = 'trading';
          $scope.broker = broker;
          $scope.brokerUrl = $sce.trustAsResourceUrl(result.url);
        }
      });
    }

    /**
     * React on platform disconnect.
     *
     * @param {*} data - ?
     * @param {*} opts - ?
     */
    function onPlatformDisconnect(data, opts) {
      void data;
      void opts;
      openIntroduction();
    }

    /**
     * React on scope destroy
     */
    function onDestroy() {
      console.log('Destroy btTradingPlatformCtrl');
    }

    /**
     * Disconnects trading platform
     */
    function disconnectTradingPlatform() {
      btTradingPlatformService.open().then(btTradingPlatformService.disconnect);
    }

    /**
     * Opens tutorial.
     */
    function openTutorial() {
      window.open('https://youtu.be/4Q-4M5GWszk', '_system');
    }
  }
})();
