(function btSnapshotCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btSnapshotCtrl', btSnapshotCtrl);

  btSnapshotCtrl.$inject = [
    '$scope',
    '$filter',
    '$q',
    '$ionicLoading',
    '$sce',
    'btRequestService',
    'CacheFactory',
    'btChartService',
  ];

  /**
   * Snapshot controller
   *
   * @ngdoc controller
   * @name btSnapshotCtrl
   * @memberOf dashboard
   * @param {*} $scope
   * @param {angular.IFilterService} $filter
   * @param {angular.IQService} $q
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {angular.ISCEService} $sce
   * @param {ecapp.IRequestService} btRequestService
   * @param {*} CacheFactory
   * @param {ecapp.IChartService} btChartService
   */
  function btSnapshotCtrl($scope, $filter, $q, $ionicLoading, $sce, btRequestService, CacheFactory, btChartService) {
    console.log('Running btSnapshotCtrl');

    console.log($scope); // TODO: Delete

    $scope.myJson = {};

    $scope.resetForm = function () {
      $scope.form = {
        date: new Date(2010, 12 - 1, 3),
        time: new Date(0, 0, 0, 13, 30),
        stock: { name: 'SPXUSD' },
        before: 15,
        after: 60,
      };
    };

    $scope.resetForm();

    $scope.loadSnapshot = function (isValidForm) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Loading Snapshots...</p>' });

      if (!isValidForm) {
        $ionicLoading.hide();
        alert('Please fill in all the required fields correctly');
        return;
      }

      $scope.myJson = {};

      console.log('Load snapshot'); // TODO: Delete

      var data = {};
      //collect data from the form and change the time format for to seconds.
      for (var key in $scope.form) {
        if ($scope.form.hasOwnProperty(key)) {
          if (key !== 'time') {
            if (key === 'date') {
              data[key] = Math.round(
                $scope.form['date'].setUTCHours($scope.form['time'].getHours(), $scope.form['time'].getMinutes()) /
                  1000.0
              );
            } else {
              data[key] = $scope.form[key];
            }
          }
        }
      }
      console.log(data); // TODO: Delete

      var promise_stock1 = btRequestService.getReleaseData(data.stock.name, data.date, data.before, data.after);

      $q.all([promise_stock1])
        .then(function (data) {
          if (data[0].error) {
            console.log('' + data[0].error + ' Description: ' + data[0].desc);
            $ionicLoading.hide();
            return;
          }

          var base = null;
          if (data[0].meta.err || data[0].meta.error) {
            console.log('Error: ' + data[0].meta.err);
          } else {
            data[0].meta.text = $sce.trustAsHtml(data[0].meta.text);
            $scope.metaData = data[0].meta;
            base = data[0].meta['prices']['base'];
          }

          var range = btChartService.getBound(data[0].values);
          $scope.myJson = btChartService.buildStockChart(
            data[0].symbol,
            range,
            data[0].values,
            data[0].timestamp * 1000,
            base
          );

          $ionicLoading.hide();
        })
        .catch(function (error) {
          $ionicLoading.hide();
          console.log('ErrorLog ' + error);
        });
    };
  }
})();
