/**
 * Created by Sergey on 8/6/18.
 */

(function btTradingIntegrationCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btTradingIntegrationCtrl', btTradingIntegrationCtrl);

  btTradingIntegrationCtrl.$inject = [
    '$scope',
    '$sce',
    '$timeout',
    '$analytics',
    '$state',
    'btTradingPlatformService',
    'btMarketing',
    'btSettingsService',
  ];

  /**
   * Controller for about us page.
   *
   * @ngdoc controller
   * @name ecapp.btTradingIntegrationCtrl
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ISCEService} $sce
   * @param {angular.ITimeoutService} $timeout
   * @param {ext.IAnalyticsService} $analytics
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.ITradingPlatformService} btTradingPlatformService
   * @param {ecapp.IMarketing} btMarketing
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btTradingIntegrationCtrl(
    $scope,
    $sce,
    $timeout,
    $analytics,
    $state,
    btTradingPlatformService,
    btMarketing,
    btSettingsService
  ) {
    console.log('Running btTradingIntegrationCtrl');

    $scope.hasError = false;
    $scope.errorMessage = '';
    $scope.hasWidget = false;

    $scope.brokers = {
      finamark: $sce.trustAsResourceUrl(btTradingPlatformService.getPlatformById('optimusfutures').url),
      ig: $sce.trustAsResourceUrl(btTradingPlatformService.getPlatformById('ig').url),
      oanda: $sce.trustAsResourceUrl(btTradingPlatformService.getPlatformById('oanda').url),
    };

    $scope.$on('$destroy', onDestroy);
    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);

    activate();

    /**
     * Activate controller
     */
    function activate() {
      console.log('Activate btTradingIntegrationCtrl');
    }

    /**
     * React on scope destroy
     */
    function onDestroy() {
      console.log('Destroy btTradingIntegrationCtrl');
    }

    /**
     * React on view loaded
     */
    function onViewLoaded() {
      onViewOpen();
    }

    /**
     * React on view entered
     */
    function onViewEnter() {
      onViewOpen();
    }

    /**
     * React on view loaded or entered
     */
    function onViewOpen() {
      $timeout(function () {
        $analytics.eventTrack('open', { category: 'dashboard', label: 'trading-integration' });
      }, 1000);
    }
  }
})();
