'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btPeriodPicker
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btPeriodPicker', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          fromDate: '=',
          tillDate: '=',
          maxDate: '=',
        } /*,
       controller: function ($scope, $filter) {
       var numberOfMonth;
       var year = parseInt($filter('date')($scope.fromDate, 'yyyy'));
       var month = parseInt($filter('date')($scope.fromDate, 'MM'));

       //$scope.maxDate = (year+2).toString() + "-" + $filter('date')($scope.fromDate, 'MM') +"-01";

       },*/,
        // TODO: Check which datetime picker is the best and use it!
        template: $templateCache.get('dashboard/directives/common/bt-period-picker.html'),
      };
    },
  ]);
