// cspell:words accuracycorrelation avgRARBSCORE avgcorrSCORE erroravgRARB erroravgcorr simpleerror simpleerrorSCORE negcorr neucorr poscorr

(function btCorrelationCtrlClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = "btCorrelationCtrlClosure";

  angular.module('dashboard').controller('btCorrelationCtrl', btCorrelationController);

  btCorrelationController.$inject = [
    '$scope',
    '$rootScope',
    '$filter',
    '$q',
    '$ionicLoading',
    'btRequestService',
    'btChartService',
    '$interval',
  ];

  /**
   * This is correlation page controller. It handles all process of realtime and interval charts and Uri's widget.
   *
   * @ngdoc controller
   * @name btCorrelationCtrl
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IFilterService} $filter - Filters are used for formatting data displayed to the user.
   * @param {angular.IQService} $q - promise interface (used once)
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ecapp.IRequestService} btRequestService - request-factory
   * @param {ecapp.IChartService} btChartService - service for building charts
   * @param {angular.IIntervalService} $interval
   */
  function btCorrelationController(
    $scope,
    $rootScope,
    $filter,
    $q,
    $ionicLoading,
    btRequestService,
    btChartService,
    $interval
  ) {
    console.log('Running btCorrelationCtrl!');

    var intervalTimer = null;

    // cancel $interval on controller destroy
    $scope.$on('$destroy', btCorrelationCtrlOnDestroy);

    $scope.hasWidget = 1;
    $scope.myJson = {};
    $scope.myJsonNorma = {};
    $scope.myJsonPredict = null;
    $scope.myChartData = {};
    $scope.lastValues = {};
    $scope.changeMode = {};
    $scope.sampleData = {};

    $scope.selectorOffsets = [
      { name: '1 hour', value: '60' },
      { name: '1.5 hour', value: '90' },
      { name: '2 hours', value: '120' },
      { name: '4 hours', value: '240' },
    ];

    $scope.calculate = calculate;
    $scope.init = init;

    //$scope.changeModeChange = function changeModeChange() {
    //  $scope.minOffset = $scope.selectorOffsets[0];
    //
    //}

    $scope.threshold = 0.1;

    $scope.init();

    /**
     *
     */
    function btCorrelationCtrlOnDestroy() {
      $interval.cancel(intervalTimer);
      console.log('Controller btCorrelationCtrl was destroyed!');
    }

    /**
     * Creating charts in tab.
     *
     * @ngdoc method
     * @name BuildCharts
     * @memberOf dashboard.btCorrelationCtrl
     * @param {*} jsonObject - in this object stored all data for all charts:
     *  time-lines, arrays of values, names for charts, etc.
     *  For some charts we calculate ranges.
     */
    function buildCharts(jsonObject) {
      var gettingValue; // For URi's widget
      var parameters = [
        {
          name: 'Current Correlation 75',
          value: jsonObject['correlation75'][jsonObject['correlation75'].length - 1],
        },
        {
          name: 'Current Correlation 84',
          value: jsonObject['correlation84'][jsonObject['correlation84'].length - 1],
        },
        {
          name: 'Current Correlation 95',
          value: jsonObject['correlation95'][jsonObject['correlation95'].length - 1],
        },
        { name: 'Predict For Next Bar', value: jsonObject['predict'][jsonObject['predict'].length - 1] },

        { name: 'accuracycorrelation', value: jsonObject['accuracycorrelation'] },
        { name: 'avgRARBSCORE', value: jsonObject['avgRARBSCORE'] },
        { name: 'avgcorrSCORE', value: jsonObject['avgcorrSCORE'] },
        { name: 'erroravgRARB', value: jsonObject['erroravgRARB'] },
        { name: 'erroravgcorr', value: jsonObject['erroravgcorr'] },
        { name: 'simpleerror', value: jsonObject['simpleerror'] },
        { name: 'simpleerrorSCORE', value: jsonObject['simpleerrorSCORE'] },
        { name: 'negcorr', value: jsonObject['negcorr'] },
        { name: 'neucorr', value: jsonObject['neucorr'] },
        { name: 'poscorr', value: jsonObject['poscorr'] },
      ];

      $scope.myJson = btChartService.buildScopeChart(
        'Correlations',
        jsonObject['time'].reverse(),
        [
          { text: 'Cor 0.75', values: jsonObject['correlation75'] },
          { text: 'Cor 0.84', values: jsonObject['correlation84'] },
          { text: 'Cor 0.95', values: jsonObject['correlation95'] },
        ],
        null
      );

      var range1 = btChartService.getBound(jsonObject['stock1']['values']);
      $scope.myJsonReal1 = btChartService.buildStockChartRealTime(
        jsonObject['stock1']['symbol'],
        range1,
        jsonObject['stock1']['values'].reverse()
      );
      var range2 = btChartService.getBound(jsonObject['stock2']['values']);
      $scope.myJsonReal2 = btChartService.buildStockChartRealTime(
        jsonObject['stock2']['symbol'],
        range2,
        jsonObject['stock2']['values'].reverse()
      );
      $scope.parameters = parameters;
      $scope.parametersData = parameters;

      $scope.lastValues = [
        { correlation95: jsonObject['correlation95'][jsonObject['correlation95'].length - 1] },
        { correlation84: jsonObject['correlation84'][jsonObject['correlation84'].length - 1] },
        { correlation75: jsonObject['correlation75'][jsonObject['correlation75'].length - 1] },
      ];

      if (
        jsonObject['correlation84'][jsonObject['correlation84'].length - 1] <
        jsonObject['correlation75'][jsonObject['correlation75'].length - 1]
      ) {
        gettingValue = 1;
      } else {
        gettingValue = -1;
      }
      $scope.sampleData = {
        correlation: jsonObject['correlation95'][jsonObject['correlation95'].length - 1],
        getting: gettingValue,
      };
    }

    /**
     * This function will call in interval (timer), it will make request for getting data for charts and then call BuildCharts ()
     *
     * @ngdoc method
     * @name reBuild
     * @memberOf dashboard.btCorrelationCtrl
     * @param {*} name1 - name for stock #1
     * @param {*} name2 - name for stock #2
     * @param {*} startDate - date of start period
     * @param {*} endDate - date of end period
     * @param {*} offset - number of skipping bars at start (need for correlation calculation)
     * @param {*} bars - number of bars in charts
     * @param {*} interval - get data for each X minutes, days, etc
     */
    function reBuild(name1, name2, startDate, endDate, offset, bars, interval) {
      var promiseCorrelationLocal = btRequestService.getCorrelationMixed(
        name1,
        name2,
        0.95,
        offset,
        startDate,
        endDate,
        bars,
        interval
      );

      $q.all([promiseCorrelationLocal]).then(function (data) {
        var jsonObject = angular.fromJson(data[0]);
        buildCharts(jsonObject);
      });
    }

    /**
     * This function will called on load fo controller (when we open correlation tab, we want to run realtime
     * charts, so we need to make all calculations).
     * And this function will called onClick on button in the form.
     * In this function started timer for making request each 30 second.
     *
     * @ngdoc method
     * @name calculate
     * @memberOf dashboard.btCorrelationCtrl
     * @param {*} isValidForm - validate form before making requests
     * @param {*} onLoadInit - if run function on start we need to make initialization of some variables
     */
    function calculate(isValidForm, onLoadInit) {
      var barsNumber = 0;
      var interval;
      var offsetCor;
      var delta;
      var startDate, endDate;
      var name1;
      var name2;
      var yesterday = new Date();

      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Loading Correlation Data...</p>' });

      if (!isValidForm) {
        $ionicLoading.hide();
        alert('Please fill in all the required fields correctly');
        return;
      }

      if (onLoadInit) {
        $scope.form = {
          stock1: { name: 'GASUSD' },
          stock2: { name: 'WTIUSD' },
          minOffset: $scope.selectorOffsets[1],
          from: new Date(yesterday - 24 * 60 * 60 * 1000),
          till: yesterday,
          changeMode: { checked: true },
        };

        name1 = $scope.form.stock1.name;
        name2 = $scope.form.stock2.name;

        barsNumber = 90;
        offsetCor = 15;
        interval = 1;
      }

      name1 = $scope.form.stock1.name;
      name2 = $scope.form.stock2.name;
      startDate = $filter('date')($scope.form.from, 'yyyy/MM/dd/00/00');
      endDate = $filter('date')($scope.form.till, 'yyyy/MM/dd/00/00');
      delta = $scope.form.till - $scope.form.from;

      if ($scope.form.changeMode.checked) {
        offsetCor = 15;
        interval = 1;
        barsNumber = $scope.form.minOffset.value;
      } else {
        $interval.cancel(intervalTimer);
        offsetCor = 20;
        barsNumber = 0;
        if (delta <= 5 * 86400000) interval = 10;
        else if (delta <= 15 * 86400000) interval = 30;
        else if (delta <= 30 * 86400000) interval = 60;
        else interval = 960;
      }

      var promise = btRequestService.getCorrelationMixed(
        name1,
        name2,
        0.95,
        offsetCor,
        startDate,
        endDate,
        barsNumber,
        interval
      );

      $q.all([promise])
        .then(function (data) {
          var jsonObject = angular.fromJson(data[0]);

          buildCharts(jsonObject);
          if (intervalTimer != null) {
            $interval.cancel(intervalTimer);
          }

          if ($scope.form.changeMode.checked) {
            intervalTimer = $interval(correlationFunc, 15000);
          }
          $ionicLoading.hide();
        })
        .catch(function (error) {
          $ionicLoading.hide();
          console.log('ErrorLog ' + error);
        });

      /**
       *
       */
      function correlationFunc() {
        if (gDebug) console.log(gPrefix, 'Interval was called (correlationFunc)');
        reBuild(name1, name2, startDate, endDate, offsetCor, barsNumber, interval);
      }

      $scope.hasResults = true;
    }

    /**
     * Calculate all data and building charts for realtime mode.
     * If we just open correlation tab - it will be called.
     *
     * @ngdoc method
     * @name init
     * @memberOf dashboard.btCorrelationCtrl
     */
    function init() {
      $scope.calculate(true, true);
    }
  }
})();
