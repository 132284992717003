/**
 * Created by Sergey Panpurin on 12/18/2017.
 */

/* global Datafeeds */

(function btRealTimeChartClosure() {
  'use strict';

  var gDebug = false;

  angular
    .module('dashboard')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btRealTimeChart
     * @memberOf ecapp
     */
    .directive('btRealTimeChart', directive);

  directive.$inject = ['$templateCache', '$interval', 'btDateService'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IDateService} btDateService
   * @return {angular.IDirective}
   */
  function directive($templateCache, $interval, btDateService) {
    return {
      restrict: 'E',
      scope: {
        symbol: '@',
        interval: '@',
        hideSideToolbar: '@?',
        skipTimezone: '@?',
      },
      template: $templateCache.get('dashboard/directives/common/bt-real-time-chart.html'),
      controller: controller,
      link: function LinkFn(scope, elem, attr, ctrl) {
        void attr;
        void ctrl;

        if (gDebug) {
          console.log('btRealTimeChart linked');
          console.log('Check real time chart ' + elem[0].querySelector('#' + scope.id));
          console.log('Check real time chart ' + elem[0].parentElement);
        }

        scope.intervalHandler = $interval(loadTradingView, 100, 0, false);

        var timezone = localStorage.getItem('btChartTimeZone') || 'Etc/UTC';

        /**
         *
         */
        function loadTradingView() {
          var datafeed;
          var INTERVAL = 10000;
          var url = 'https://bt-api-server-staging.herokuapp.com/api/udf';
          // if (window.isDevelopment) url = 'http://localhost:3005/api/udf';
          var API_KEY = 'ZXdnam9WcGNnZkVnTTg3ZFl2dUhKUG5xTjJxZzZlT09mNGxmTVJubg';
          datafeed = new Datafeeds.UDFCompatibleDatafeed(url, atob(API_KEY + '=='), INTERVAL);

          if (gDebug) console.log('Interval was called (loadTradingView) - ' + scope.id);
          if ($('#' + scope.id).length) {
            var params = {
              // Old properties
              enable_publishing: false,
              allow_symbol_change: false,
              hideideas: true,
              hide_side_toolbar: scope.hideSideToolbarValue,
              show_popup_button: true,
              popup_width: '1000',
              popup_height: '650',

              // New properties
              debug: false,
              datafeed: datafeed,
              library_path: '/charting_library/',
              autosize: true,
              symbol: 'FX:' + scope.symbol,
              interval: scope.interval,
              timezone: timezone,
              theme: 'Dark',
              locale: 'en',
              disabled_features: ['header_saveload'],
              enabled_features: [],
              charts_storage_url: 'https://saveload.tradingview.com',
              charts_storage_api_version: '1.1',
              client_id: 'tradingview.com',
              user_id: 'public_user_id',
              container: scope.id,
              overrides: {},
              // favorites: {
              //   intervals: ['1D'],
              //   chartTypes: ['Line']
              // },
              studies_overrides: {},
            };

            if (scope.skipTimezone === 'true') {
              params['timezone'] = btDateService.suggestTradingViewTimeZone();
            }

            new window.TradingView.widget(params);
            $interval.cancel(scope.intervalHandler);
          }
        }
      },
    };
  }

  controller.$inject = ['$scope', '$interval'];

  /**
   *
   * @param {*} $scope
   * @param {angular.IIntervalService} $interval
   */
  function controller($scope, $interval) {
    console.log('btRealTimeChart created');

    /**
     *
     * @param {*} min
     * @param {*} max
     * @return {any}
     */
    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    }

    $scope.id = 'real-time-' + getRandomInt(0, 9999999);

    $scope.hideSideToolbarValue = ($scope.hideSideToolbar || 'true') === 'true';
    $scope.$on('$destroy', function () {
      console.log('btRealTimeChart destroyed');
      $interval.cancel($scope.intervalHandler);
    });
  }
})();
