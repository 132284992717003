(function btNewsCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btNewsCtrl', btNewsCtrl);

  btNewsCtrl.$inject = ['$scope', 'btShareScopeService'];

  /**
   * News controller
   *
   * @ngdoc controller
   * @name btNewsCtrl
   * @memberOf dashboard
   * @param {ecapp.ICustomScope} $scope
   * @param {ecapp.IShareScopeService} btShareScopeService
   */
  function btNewsCtrl($scope, btShareScopeService) {
    console.log('Running btNewsCtrl');

    // $scope.hasWidget = 1;
    // $scope.height = "600px";

    // $scope.channels = [
    //   {
    //     sourceType: 'profile',
    //     screenName: 'bettertraderpro'
    //   },
    //   {
    //     sourceType: 'list',
    //     ownerScreenName: 'bettertraderpro',
    //     slug: 'bettertrader-rss'
    //   },
    //   {
    //     sourceType: 'collection',
    //     id: '539487832448843776'
    //   },
    //   {
    //     sourceType: 'likes',
    //     screenName: 'bettertraderpro'
    //   }
    // ];
    // $scope.settings = {nChannels: $scope.channels.length};
    // $scope.changeNChannels = changeNChannels;

    $scope.isReady = false;

    initialize();

    /**
     *
     */
    function initialize() {
      btShareScopeService.wait().then(function () {
        $scope.isReady = true;
        window.btChatUserId = btShareScopeService.getAccountInfoField('id');
        window.btChatUserName = btShareScopeService.getAccountInfoField('firstName');
      });
      // if (!!window.twttr && !!window.twttr.widgets) {
      //   $scope.channels.forEach(function (channel, i) {
      //     createTimeline(channel, i);
      //   });
      // } else {
      //   setTimeout(initialize, 100);
      // }
    }

    // function createTimeline(channel, i) {
    //   twttr.widgets.createTimeline(
    //     channel,
    //     document.getElementById('twitter-list-' + i)
    //   );
    // }
    //
    // function changeNChannels() {
    //   var n = $scope.channels.length;
    //   if ($scope.settings.nChannels > n) {
    //     for (var i = 0; i < $scope.settings.nChannels - n; i++) {
    //       var data = {
    //         sourceType: 'profile',
    //         screenName: 'bettertraderpro'
    //       };
    //
    //       $scope.channels.push(data);
    //
    //       setTimeout(createTimeline.bind(null, data, n + i), 100);
    //     }
    //   }
    //
    //   if ($scope.settings.nChannels < n) {
    //     for (var j = 0; j < n - $scope.settings.nChannels; j++) {
    //       $scope.channels.pop();
    //     }
    //   }
    // }
  }
})();
