/**
 * Created by Sergey on 5/27/19.
 */

(function btProMembershipTabControllerClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btProMembershipTabController';

  angular.module('dashboard').controller('btProMembershipTabController', btProMembershipTabController);

  btProMembershipTabController.$inject = ['$scope'];

  /**
   * This is a controller for pro membership page.
   *
   * @ngdoc controller
   * @name dashboard.btProMembershipTabController
   * @param {ecapp.ICustomScope} $scope
   */
  function btProMembershipTabController($scope) {
    console.log('Running btProMembershipTabController');

    var vm = this;
  }
})();
