'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btNoResult
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btNoResult', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('dashboard/directives/common/bt-no-result.html'),
      };
    },
  ]);
