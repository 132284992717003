'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btMetaData
   * @memberOf dashboard
   * @description
   *  Deprecated
   */
  .directive('btMetaData', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btData: '=',
          btText: '=',
        },
        template: $templateCache.get('dashboard/directives/common/meta/bt-meta-data.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btMetaReaction
   * @memberOf dashboard
   * @description
   *  Deprecated
   */
  .directive('btMetaReaction', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btLabel: '@',
          btReaction: '=',
        },
        template: $templateCache.get('dashboard/directives/common/meta/bt-meta-reaction.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btMetaRelease
   * @memberOf dashboard
   * @description
   *  Deprecated
   */
  .directive('btMetaRelease', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btLabel: '@',
          btRelease: '=',
        },
        template: $templateCache.get('dashboard/directives/common/meta/bt-meta-release.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btMetaBar
   * @memberOf dashboard
   * @description
   *  Deprecated
   */
  .directive('btMetaBar', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btLabel: '@',
          btData: '=',
          btStrengthThreshold: '=',
        },
        template: $templateCache.get('dashboard/directives/common/meta/bt-meta-bar.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btMetaPrices
   * @memberOf dashboard
   * @description
   *  Deprecated
   */
  .directive('btMetaPrices', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btLabel: '@',
          btPrices: '=',
          btATR: '=btAtr',
        },
        template: $templateCache.get('dashboard/directives/common/meta/bt-meta-prices.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btMetaRiskReward
   * @memberOf dashboard
   * @description
   *  Deprecated
   */
  .directive('btMetaRiskReward', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btLabel: '@',
          btData: '=',
        },
        template: $templateCache.get('dashboard/directives/common/meta/bt-meta-risk-reward.html'),
        controller: [
          '$scope',
          function ($scope) {
            $scope.btClass = $scope.btLabel.toLowerCase();
          },
        ],
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btMetaSentiment
   * @memberOf dashboard
   * @description
   *  Deprecated
   */
  .directive('btMetaSentiment', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btLabel: '@',
          btSentiment: '=',
        },
        template: $templateCache.get('dashboard/directives/common/meta/bt-meta-sentiment.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btMetaSummary
   * @memberOf dashboard
   * @description
   *  Deprecated
   */
  .directive('btMetaSummary', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        scope: {
          btLabel: '@',
          btStats: '=',
        },
        template: $templateCache.get('dashboard/directives/common/meta/bt-meta-summary.html'),
        controller: [
          '$scope',
          'btChartService',
          function ($scope, btChartService) {
            $scope.myConfig = btChartService.buildPieChart();

            $scope.$watch(
              'btStats',
              function () {
                $scope.myConfig.series[0].values[0] = $scope.btStats.reg;
                $scope.myConfig.series[1].values[0] = $scope.btStats.pos;
                $scope.myConfig.series[2].values[0] = $scope.btStats.neg;
              },
              true
            );
          },
        ],
      };
    },
  ]);
