'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btIntervalPicker
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btIntervalPicker', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          before: '=',
          after: '=',
        },
        // TODO: Check which datetime picker is the best and use it!
        template: $templateCache.get('dashboard/directives/common/bt-interval-picker.html'),
        controller: [
          '$scope',
          function ($scope) {
            $scope.minBefore = 10;
            $scope.maxBefore = 120;

            $scope.minAfter = 10;
            $scope.maxAfter = 240;
          },
        ],
      };
    },
  ]);
