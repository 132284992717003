/* global addEmbedIframe: true */

(function btCommunityTabControllerClosure() {
  'use strict';

  /** @namespace window.gitter.Chat */

  var gDebug = false;
  var gPrefix = 'btCommunityTabController';

  angular.module('dashboard').controller('btCommunityTabController', btCommunityTabController);

  btCommunityTabController.$inject = ['$q', '$scope', '$timeout'];

  /**
   * Community tab controller
   *
   * @ngdoc controller
   * @name btCommunityTabController
   * @memberOf dashboard
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.ITimeoutService} $timeout
   */
  function btCommunityTabController($q, $scope, $timeout) {
    console.log('Running btCommunityTabController');

    $scope.page = {
      ready: false,
      loading: true,
      error: null,
    };

    $timeout(function () {
      $scope.page.ready = true;
      $scope.page.loading = false;
      $scope.page.error = null;
    }, 500);
  }
})();
