(function btSentimentWidgetClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * ???
     *
     * @ngdoc controller
     * @name btSentimentWidget
     * @memberOf dashboard
     */
    .directive('btSentimentWidget', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('dashboard/directives/common/bt-sentiment-widget.html'),
          controller: [
            '$scope',
            function ($scope) {
              $('#sw-right-button').click(function () {
                event.preventDefault();
                console.log('Scroll sentiment widget!');
                $('#sw-widgetContainer').animate(
                  {
                    scrollLeft: '+=230px',
                  },
                  300
                );
              });
              $('#sw-left-button').click(function () {
                event.preventDefault();
                console.log('Scroll sentiment widget!');
                $('#sw-widgetContainer').animate(
                  {
                    scrollLeft: '-=230px',
                  },
                  300
                );
              });

              $scope.testData = [
                {
                  name: 'USD / ILS',
                  price: 100.087,
                  difference: 0.19,
                  sentiment: 2,
                },
                {
                  name: 'USD / GBP',
                  price: 0.0018,
                  difference: -0.14,
                  sentiment: -2,
                },
                {
                  name: 'CAD / USD',
                  price: 1.053,
                  difference: 0.22,
                  sentiment: 2,
                },
                {
                  name: 'USD / JPY',
                  price: 0.0007,
                  difference: 0.06,
                  sentiment: 2,
                },
                {
                  name: 'AUD / USD',
                  price: 1.4501,
                  difference: -0.24,
                  sentiment: -2,
                },
                {
                  name: 'USD / ILS',
                  price: 100.87,
                  difference: -0.19,
                  sentiment: -2,
                },
                {
                  name: 'USD / ILS',
                  price: 100.0007,
                  difference: -0.19,
                  sentiment: 2,
                },
                {
                  name: 'USD / ILS',
                  price: 100.0009,
                  difference: -0.19,
                  sentiment: 0,
                },
                {
                  name: 'USD / ILS',
                  price: 187,
                  difference: 0.19,
                  sentiment: 2,
                },
                {
                  name: 'USD / ILS',
                  price: 21.879,
                  difference: -0.19,
                  sentiment: -2,
                },
                {
                  name: 'USD / ILS',
                  price: 100.0009,
                  difference: -0.19,
                  sentiment: 0,
                },
                {
                  name: 'USD / ILS',
                  price: 187,
                  difference: 0.19,
                  sentiment: 2,
                },
                {
                  name: 'USD / ILS',
                  price: 21.879,
                  difference: -0.19,
                  sentiment: -2,
                },
              ];
            },
          ],
        };
      },
    ])
    /**
     * ???
     *
     * @ngdoc controller
     * @name btSentimentWidgetItem
     * @memberOf dashboard
     */
    .directive('btSentimentWidgetItem', [
      '$templateCache',
      function ($templateCache) {
        return {
          restrict: 'E',
          template: $templateCache.get('dashboard/directives/common/bt-sentiment-widget-item.html'),
          scope: {
            data: '@',
          },
          controller: [
            '$scope',
            function ($scope) {
              var object = JSON.parse($scope.data);
              $scope.name = object.name;
              $scope.price = object.price;
              $scope.difference = object.difference;
              $scope.sentiment = object.sentiment;
            },
          ],
        };
      },
    ])
    /**
     * ???
     *
     * @ngdoc controller
     * @name btResponsiveNumber
     * @memberOf dashboard
     */
    .directive('btResponsiveNumber', [
      function () {
        return {
          restrict: 'E',
          scope: {
            number: '@',
          },
          controller: [
            '$scope',
            function ($scope) {
              $scope.number = parseInt($scope.number);
              console.log($scope.number);
            },
          ],
          link: function (scope, el, attr) {
            if (attr.number > 0) {
              el[0].style.color = '#48C064';
            } else if (attr.number < 0) {
              el[0].style.color = '#CF3040';
            } else {
              el[0].style.color = '';
            }
          },
        };
      },
    ]);
})();
