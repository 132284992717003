'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btEventPicker
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btEventPicker', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          eventsList: '=',
          eventId: '=',
        },
        controller: [
          '$scope',
          function ($scope) {
            //default value
            $scope.eventId = 300;
          },
        ],
        template: $templateCache.get('dashboard/directives/common/bt-event-picker.html'),
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btGoodEvent
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btGoodEvent', [
    function () {
      var isValid = function (value, eventsList) {
        if (eventsList) {
          return (
            !!eventsList.filter(function (event) {
              return event.id === parseInt(value);
            })[0] || false
          );
        } else {
          return true; // At the first load, the eventsList is undefined, I set the validator to return true so we can submit the form
        }
      };

      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ngModelCtrl) {
          ngModelCtrl.$parsers.unshift(function (viewValue) {
            ngModelCtrl.$setValidity('goodEventId', isValid(viewValue, scope.eventsList));
            return viewValue;
          });

          ngModelCtrl.$formatters.unshift(function (modelValue) {
            ngModelCtrl.$setValidity('goodEventId', isValid(modelValue, scope.eventsList));
            return modelValue;
          });
        },
      };
    },
  ]);
