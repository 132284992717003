/**
 * Created by edwin on 6/20/16.
 */

(function btDebugNWatchersClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc controller
     * @name btDebugNWatchers
     * @memberOf dashboard
     * @description
     *  Display number of watchers
     */
    .directive('btDebugNWatchers', [
      function () {
        return {
          restrict: 'E',
          scope: false,
          controller: [
            '$scope',
            function ($scope) {
              $scope.nWatchers = (function (root) {
                root = angular.element(root || document.documentElement);
                // var watcherCount = 0;

                /**
                 *
                 * @param {*} element
                 * @return {any}
                 */
                function getElemWatchers(element) {
                  var isolateWatchers = getWatchersFromScope(element.data().$isolateScope);
                  var scopeWatchers = getWatchersFromScope(element.data().$scope);
                  var watchers = scopeWatchers.concat(isolateWatchers);
                  angular.forEach(element.children(), function (childElement) {
                    watchers = watchers.concat(getElemWatchers(angular.element(childElement)));
                  });
                  return watchers;
                }

                /**
                 *
                 * @param {*} scope
                 * @return {any}
                 */
                function getWatchersFromScope(scope) {
                  if (scope) {
                    return scope.$$watchers || [];
                  } else {
                    return [];
                  }
                }

                return getElemWatchers(root).length;
              })();
            },
          ],
          template: '<div>{{nWatchers}}</div>',
        };
      },
    ]);
})();
