'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btPictureMover
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btPictureMover', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('dashboard/directives/sentiment/bt-picture-mover.html'),
        scope: {
          index: '=',
          totalAmount: '@',
        },
        controller: [
          '$scope',
          function ($scope) {
            $scope.index = parseInt($scope.index);
            $scope.totalAmount = parseInt($scope.totalAmount);

            $scope.loadFirstImage = function () {
              $scope.index = 0;
            };

            $scope.loadPreviousImage = function () {
              if ($scope.index <= 0) return;
              $scope.index--;
            };

            $scope.loadNextImage = function () {
              if ($scope.index >= $scope.totalAmount - 1) return;
              $scope.index++;
            };

            $scope.loadLastImage = function () {
              $scope.index = parseInt($scope.totalAmount) - 1;
            };
          },
        ],
      };
    },
  ]);
