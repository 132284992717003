'use strict';

angular
  .module('dashboard')
  /**
   * @ngdoc controller
   * @name btMarketImpact
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btMarketImpact', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('dashboard/directives/sentiment/bt-market-impact.html'),
        scope: {
          type: '@',
          data: '=',
          time: '=',
        },
        controller: [
          '$scope',
          function ($scope) {
            $scope.type = $scope.type.toLowerCase();
            if ($scope.type === 'buy') {
              $scope.buy = true;
              $scope.title = 'BUY';

              $scope.activeClass = 'positive';
            } else if ($scope.type === 'sell') {
              $scope.buy = false;
              $scope.title = 'SELL';

              $scope.activeClass = 'negative';
            }
          },
        ],
      };
    },
  ])
  /**
   * @ngdoc controller
   * @name btImpactReaction
   * @memberOf dashboard
   * @description
   *  ???
   */
  .directive('btImpactReaction', [
    '$templateCache',
    function ($templateCache) {
      return {
        restrict: 'E',
        template: $templateCache.get('dashboard/directives/sentiment/bt-impact-reaction.html'),
        scope: {
          reaction: '=',
        },
      };
    },
  ]);
