/**
 * Created by Sergey Panpurin on 5/23/2017.
 */

(function btBackTesterTutorialClosure() {
  'use strict';

  angular
    .module('dashboard')
    /**
     * @ngdoc directive
     * @name btBackTesterTutorial
     * @memberOf dashboard
     * @description
     *  This directive ???
     */
    .directive('btBackTesterTutorial', [
      function () {
        return {
          restrict: 'E',
          replace: true,
          scope: {},
          template: '<div></div>',
        };
      },
    ]);
})();
