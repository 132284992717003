/**
 * Created by Sergey on 12/10/19.
 */

(function btTwitterTimelineClosure() {
  'use strict';

  /**
   * This directive ...
   *
   * @ngdoc directive
   * @name btTwitterTimeline
   * @memberOf ecapp
   * @restrict E
   * @scope
   * @param {} channel
   * @param {number} index
   */

  /**
   * This controller ...
   * @ngdoc controller
   * @name btTwitterTimelineController
   * @memberOf ecapp
   */

  /** Directive */
  angular
    .module('ecapp')
    .directive('btTwitterTimeline', angularDirective)
    .controller('btTwitterTimelineController', angularController);

  angularDirective.$inject = ['$templateCache'];

  /**
   * @typedef {angular.IScope} btTwitterTimelineScope
   * @property {btCustomTwitterAccount} channel - ?
   * @property {number} index - ?
   */

  /**
   * Implementation of Notification Center Directive
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function angularDirective($templateCache) {
    return {
      restrict: 'E',
      scope: {
        channel: '=',
        index: '=',
      },
      template: $templateCache.get('dashboard/directives/common/bt-twitter-timeline.html'),
      controller: 'btTwitterTimelineController',
    };
  }

  angularController.$inject = ['$scope', '$q'];

  /**
   * Implementation of Notification Center Controller
   *
   * @param {btTwitterTimelineScope} $scope
   * @param {angular.IQService} $q - promise interface
   */
  function angularController($scope, $q) {
    // The rest of the activation
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * Controller constructor
     */
    function activate() {
      makeWaitingPromise(isTwitterAPILoaded)
        .then(function () {
          return makeWaitingPromise(isContainerLoaded);
        })
        .then(function () {
          return createTimeline($scope.channel, $scope.index);
        });
    }

    /**
     * On destroy
     */
    function onDestroy() {}

    /**
     *
     * @param {*} checkCriteria
     * @return {angular.IPromise}
     */
    function makeWaitingPromise(checkCriteria) {
      var deferred = $q.defer();
      waitFor(deferred.resolve, deferred.reject);
      return deferred.promise;

      /**
       *
       * @param {*} resolve
       * @param {*} reject
       */
      function waitFor(resolve, reject) {
        if (checkCriteria()) {
          resolve();
        } else {
          setTimeout(function () {
            waitFor(resolve, reject);
          }, 200);
        }
      }
    }

    /**
     *
     * @return {boolean}
     */
    function isTwitterAPILoaded() {
      return !!window.twttr && !!window.twttr.widgets;
    }

    /**
     *
     * @return {boolean}
     */
    function isContainerLoaded() {
      return !!document.getElementById('twitter-list-' + $scope.index);
    }

    /**
     *
     * @param {btCustomTwitterAccount} channel
     * @param {number} i
     */
    function createTimeline(channel, i) {
      // console.log('TEST: Creating timeline for ' + channel.name);
      window.twttr.widgets
        .createTimeline(
          {
            sourceType: 'profile',
            screenName: channel.name,
          },
          document.getElementById('twitter-list-' + i),
          {
            theme: 'dark',
          }
        )
        .then(function (el) {
          void el;
          // console.log('TEST: Timeline for ' + channel.name + ' created.');
        })
        .catch(function (reason) {
          console.error(reason);
        });
    }
  }
})();
